$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-tabs;

.#{$C} {
  .#{$C}__list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 1.5rem 1.375rem;

    .#{$C}__tab {
      button {
        @include focusable;
        background: var(--wxc-tab--normal--background);
        color: var(--wxc-tab--normal--color);
        border: var(--wxc-tab--normal--border);
        cursor: pointer;
        font-family: $brand-font-medium;
        font-size: 0.875rem;
        line-height: 1.375rem;
        padding: 0.1875rem 1rem;
        border-radius: 0.875rem;

        &:hover {
          color: var(--wxc-tab--hover--color);
          background: var(--wxc-tab--hover--background);
          border: var(--wxc-tab--hover--border);
        }

        &:active {
          color: var(--wxc-tab--active--color);
          background: var(--wxc-tab--active--background);
          border: var(--wxc-tab--active--border);
        }
      }

      &--active > button {
        color: var(--wxc-tab--active--color);
        background: var(--wxc-tab--active--background);
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .#{$C}__content {
    padding: 0 1.375rem;
  }
}
