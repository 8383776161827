$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-button;

.#{$C} {
  @include focusable;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  font-family: $brand-font-medium;
  line-height: 1;
  padding: 0 0.75rem;

  &:not([wxc-disabled]) {
    cursor: pointer;
  }
}

.#{$C}--join {
  background: var(--wxc-button--join--normal--background);
  border: var(--wxc-button--join--normal--border);
  color: var(--wxc-button--join--normal--color);

  &:hover {
    background: var(--wxc-button--join--hover--background);
    color: var(--wxc-button--join--hover--color);
    border: var(--wxc-button--join--hover--border);
  }

  &:active, &.#{$C}--pressed {
    background: var(--wxc-button--join--active--background);
    color: var(--wxc-button--join--active--color);
    border: var(--wxc-button--join--active--border);
  }

  &[wxc-disabled] {
    background: var(--wxc-button--join--disabled--background);
    color: var(--wxc-button--join--disabled--color);
    border: var(--wxc-button--join--disabled--border);
  }
}

.#{$C}--default,
.#{$C}--toggle {
  background: var(--wxc-button--default--normal--background);
  color: var(--wxc-button--default--normal--color);
  border: var(--wxc-button--default--normal--border);

  &:hover {
    background: var(--wxc-button--default--hover--background);
    color: var(--wxc-button--default--hover--color);
    border: var(--wxc-button--default--hover--border);
  }

  &:active, &.#{$C}--pressed {
    background: var(--wxc-button--default--active--background);
    color: var(--wxc-button--default--active--color);
    border: var(--wxc-button--default--active--border);
  }

  &[wxc-disabled] {
    background: var(--wxc-button--default--disabled--background);
    color: var(--wxc-button--default--disabled--color);
    border: var(--wxc-button--default--disabled--border);
  }
}

.#{$C}--cancel {
  background: var(--wxc-button--cancel--normal--background);
  color: var(--wxc-button--cancel--normal--color);
  border: var(--wxc-button--cancel--normal--border);

  &:hover {
    background: var(--wxc-button--cancel--hover--background);
    color: var(--wxc-button--cancel--hover--color);
    border: var(--wxc-button--cancel--hover--border);
  }

  &:active, &.#{$C}--pressed {
    background: var(--wxc-button--cancel--active--background);
    color: var(--wxc-button--cancel--active--color);
    border: var(--wxc-button--cancel--active--border);
  }

  &[wxc-disabled] {
    background: var(--wxc-button--cancel--disabled--background);
    color: var(--wxc-button--cancel--disabled--color);
    border: var(--wxc-button--cancel--disabled--border);
  }
}

.#{$C}--ghost {
  background: var(--wxc-button--ghost--normal--background);
  color: var(--wxc-button--ghost--normal--color);
  border: var(--wxc-button--ghost--normal--border);
  border-radius: 50%;
  padding: 0 0.375rem;

  &:hover {
    background: var(--wxc-button--ghost--hover--background);
    color: var(--wxc-button--ghost--hover--color);
    border: var(--wxc-button--ghost--hover--border);
  }

  &:active {
    background: var(--wxc-button--ghost--active--background);
    color: var(--wxc-button--ghost--active--color);
    border: var(--wxc-button--ghost--active--border);
  }

  &[wxc-disabled] {
    background: var(--wxc-button--ghost--disabled--background);
    color: var(--wxc-button--ghost--disabled--color);
    border: var(--wxc-button--ghost--disabled--border);
  }
}

.#{$C}--primary {
  font-size: 0.938rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.875rem;
  letter-spacing: 0.015rem;
  background: var(--wxc-button--primary--normal--background);
  color: var(--wxc-button--primary--normal--color);
  border: var(--wxc-button--primary--normal--border);

  &:hover {
    background: var(--wxc-button--primary--hover--background);
    color: var(--wxc-button--primary--hover--color);
    border: var(--wxc-button--primary--hover--border);
  }

  &:active, &.#{$C}--pressed {
    background: var(--wxc-button--primary--active--background);
    color: var(--wxc-button--primary--active--color);
    border: var(--wxc-button--primary--active--border);
  }

  &[wxc-disabled] {
    background: var(--wxc-button--primary--disabled--background);
    color: var(--wxc-button--primary--disabled--color);
    border: var(--wxc-button--primary--disabled--border);
  }
}
