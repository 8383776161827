$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-interstitial-meeting;

.#{$C} {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: $WEBEX_MEETING_MIN_WIDTH;

  .#{$C}__media-container {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }

  .#{$C}__interstitial-media-container {
    flex: 1;
    min-height: 0;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .#{$C}__media {
    flex: 1;
    min-height: 0;
    align-self: stretch;
  }

  .#{$C}__info {
    margin-bottom: 1rem;
  }
}
