$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-meeting-host-authentication;

.#{$C} {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  color: var(--wxc-text-color);
  line-height: 1.375rem;
  min-width: 0;

  .#{$C}__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }

  .#{$C}__logo {
    background: var(--wxc-auth-logo--url) no-repeat center;
    width: 3.689rem;
    height: 2.625rem;
    margin-bottom: 1.25rem;
  }

  .#{$C}__title {
    font-family: $brand-font-medium;
    font-size: 1.25rem;
    line-height: 1.875rem;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .#{$C}__form-content {
    display: flex;
    flex-direction: column;
    width: 17.625rem;

    .#{$C}__input {
      margin-bottom: 1rem;
    }

    .#{$C}__start-button-spinner {
      margin-right: 0.5rem;
    }
  }

  &--phone {
    .#{$C}__title {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
