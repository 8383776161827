$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-remote-media;

.#{$C} {
  background: var(--wxc-video-background);
  border-radius: 0.5rem;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .#{$C}__media-error {
    font-family: $brand-font-medium;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: var(--wxc-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .#{$C}__error-icon {
    margin-bottom: 1.234rem;
  }

  .#{$C}__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .#{$C}__connecting-badge {
    z-index: 5;
    white-space: nowrap;
  }

  .#{$C}__connecting-spinner {
    margin-right: .6875rem;
  }

  &--remote-video-n-share {
    .#{$C}__remote-video {
      @include media-preview-mobile();
      height: 5.625rem;
      width: 10rem;
    }

    .#{$C}__remote-share {
      flex-grow: 1;
    }
  }


  &--tablet {
    &.#{$C}--remote-video-n-share {
      .#{$C}__remote-video {
        @include media-preview-tablet();
      }
    }
  }

  &--desktop {
    &.#{$C}--remote-video-n-share {
     .#{$C}__remote-video {
        @include media-preview-tablet();
      }
    }
  }

  &--desktop-xl {
    &.#{$C}--remote-video-n-share {
     .#{$C}__remote-video {
        @include media-preview-desktop-xl();
      }
    }
  }
}
