.md-theme-darkWebex,
.wxc-theme-dark,
:root {
  --wxc-browser-color-scheme: dark;
  /*--wxc-loading-logo--url: url(assets/dc7f8e1b.gif);*/
  --wxc-loading-logo--size: 11.6875rem;
  /*--wxc-auth-logo--url: url(assets/af1a3e22.svg);*/
  --wxc-text-color: hsla(0, 0%, 100%, 0.95);
  --wxc-secondary-text-color: hsla(0, 0%, 100%, 0.7);
  --wxc-warning-color: #f2990a;
  --wxc-error-color: #fc8b98;
  --wxc-muted-color: #f55868;
  --wxc-speaking-color: #3cc29a;
  --wxc-primary-background: linear-gradient(#1a1a1a, #0f0f0f);
  --wxc-secondary-background: linear-gradient(#262626, #0f0f0f);
  --wxc-video-background: hsla(0, 0%, 100%, 0.05);
  --wxc-secondary-video-background: #0f0f0f;
  --wxc-meeting-border: 1px solid hsla(0, 0%, 100%, 0.3);
  --wxc-box-shadow: none;
  --wxc-focus-box-shadow: 0 0 0 0.25rem rgba(100, 180, 250, 0.3),
    0 0 0 0.125rem #64b4fa;
  --wxc-person-status--active-color: #3cc29a;
  --wxc-person-status--dnd-color: #f55868;
  --wxc-person-status--in-a-meeting-color: #ffbe4f;
  --wxc-person-status--away-color: #adadad;
  --wxc-badge-background: #0f0f0f;
  --wxc-badge-border: 0.06rem solid hsla(0, 0%, 100%, 0.3);
  --wxc-badge-text-color: hsla(0, 0%, 100%, 0.95);
  --wxc-badge-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16),
    0 0 0.06rem rgba(0, 0, 0, 0.14);
  --wxc-avatar--placeholder-1--background: hsla(0, 0%, 100%, 0.5);
  --wxc-avatar--placeholder-2--background: #08599c;
  --wxc-avatar--placeholder-3--background: #066070;
  --wxc-avatar--placeholder-4--background: #735107;
  --wxc-avatar--placeholder-5--background: #416116;
  --wxc-avatar--placeholder-6--background: #12615a;
  --wxc-avatar--placeholder-7--background: #914017;
  --wxc-avatar--placeholder-8--background: #a12a3a;
  --wxc-avatar--placeholder-9--background: #535573;
  --wxc-avatar--placeholder-10--background: #643abd;
  --wxc-avatar--placeholder-11--background: #932099;
  --wxc-avatar--bot-status-color: #1170cf;
  --wxc-avatar--self-status-color: #279be8;
  --wxc-avatar--self-status-border: #fff;
  --wxc-avatar--text-color: #fff;
  --wxc-banner--top--background: linear-gradient(
    2.25deg,
    transparent 1.9%,
    rgba(0, 0, 0, 0.3) 50.23%,
    rgba(0, 0, 0, 0.8) 98.11%
  );
  --wxc-banner--bottom--background: rgba(0, 0, 0, 0.7);
  --wxc-icon--background: #000;
  --wxc-icon--color: #fff;
  --wxc-icon--accent-color: #3cc29a;
  --wxc-icon--muted-color: #f55868;
  --wxc-icon--error--gradient-start-color: #f0677e;
  --wxc-icon--error--gradient-stop-color: #db1f2e;
  --wxc-modal--background: #0f0f0f;
  --wxc-modal--border: 1px solid rgba(0, 0, 0, 0.11);
  --wxc-modal--box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2),
    0 0 0.0625rem rgba(0, 0, 0, 0.12);
  --wxc-button--cancel--normal--background: #db1f2e;
  --wxc-button--cancel--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--cancel--normal--border: none;
  --wxc-button--cancel--active--background: #780d13;
  --wxc-button--cancel--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--cancel--active--border: none;
  --wxc-button--cancel--hover--background: #ab0a15;
  --wxc-button--cancel--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--cancel--hover--border: none;
  --wxc-button--cancel--disabled--background: hsla(0, 0%, 100%, 0.2);
  --wxc-button--cancel--disabled--color: hsla(0, 0%, 100%, 0.4);
  --wxc-button--cancel--disabled--border: none;
  --wxc-button--default--normal--background: transparent;
  --wxc-button--default--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--default--normal--border: 1px solid hsla(0, 0%, 100%, 0.3);
  --wxc-button--default--active--background: hsla(0, 0%, 100%, 0.2);
  --wxc-button--default--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--default--active--border: 1px solid hsla(0, 0%, 100%, 0.3);
  --wxc-button--default--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-button--default--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--default--hover--border: 1px solid hsla(0, 0%, 100%, 0.3);
  --wxc-button--default--disabled--background: transparent;
  --wxc-button--default--disabled--color: hsla(0, 0%, 100%, 0.4);
  --wxc-button--default--disabled--border: 1px solid hsla(0, 0%, 100%, 0.2);
  --wxc-button--ghost--normal--background: transparent;
  --wxc-button--ghost--normal--color: #fff;
  --wxc-button--ghost--normal--border: none;
  --wxc-button--ghost--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-button--ghost--hover--color: #fff;
  --wxc-button--ghost--hover--border: none;
  --wxc-button--ghost--active--background: hsla(0, 0%, 100%, 0.2);
  --wxc-button--ghost--active--color: #fff;
  --wxc-button--ghost--active--border: none;
  --wxc-button--ghost--disabled--background: hsla(0, 0%, 100%, 0.2);
  --wxc-button--ghost--disabled--color: hsla(0, 0%, 100%, 0.4);
  --wxc-button--ghost--disabled--border: none;
  --wxc-button--join--normal--background: #1d805f;
  --wxc-button--join--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--join--normal--border: none;
  --wxc-button--join--active--background: #134231;
  --wxc-button--join--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--join--active--border: none;
  --wxc-button--join--hover--background: #185e46;
  --wxc-button--join--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--join--hover--border: none;
  --wxc-button--join--disabled--background: hsla(0, 0%, 100%, 0.2);
  --wxc-button--join--disabled--color: hsla(0, 0%, 100%, 0.4);
  --wxc-button--join--disabled--border: none;
  --wxc-button--primary--normal--background: hsla(0, 0%, 100%, 0.95);
  --wxc-button--primary--normal--color: rgba(0, 0, 0, 0.95);
  --wxc-button--primary--normal--border: none;
  --wxc-button--primary--active--background: hsla(0, 0%, 100%, 0.7);
  --wxc-button--primary--active--color: rgba(0, 0, 0, 0.95);
  --wxc-button--primary--active--border: none;
  --wxc-button--primary--hover--background: hsla(0, 0%, 100%, 0.8);
  --wxc-button--primary--hover--color: rgba(0, 0, 0, 0.95);
  --wxc-button--primary--hover--border: none;
  --wxc-button--primary--disabled--background: hsla(0, 0%, 100%, 0.2);
  --wxc-button--primary--disabled--color: hsla(0, 0%, 100%, 0.4);
  --wxc-button--primary--disabled--border: none;
  --wxc-spinner--circle--color: hsla(0, 0%, 100%, 0.11);
  --wxc-spinner--moving--color: hsla(0, 0%, 100%, 0.95);
  --wxc-tab--normal--background: transparent;
  --wxc-tab--normal--color: hsla(0, 0%, 100%, 0.7);
  --wxc-tab--normal--border: none;
  --wxc-tab--active--background: hsla(0, 0%, 100%, 0.2);
  --wxc-tab--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-tab--active--border: none;
  --wxc-tab--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-tab--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-tab--hover--border: none;
  --wxc-dropdown--normal--background: #000;
  --wxc-dropdown--normal--color: hsla(0, 0%, 100%, 0.7);
  --wxc-dropdown--normal--box-shadow: none;
  --wxc-dropdown--normal--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-dropdown--normal--outline: none;
  --wxc-dropdown--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-dropdown--hover--color: hsla(0, 0%, 100%, 0.7);
  --wxc-dropdown--hover--box-shadow: none;
  --wxc-dropdown--hover--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-dropdown--hover--outline: none;
  --wxc-dropdown--active--background: hsla(0, 0%, 100%, 0.11);
  --wxc-dropdown--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-dropdown--active--box-shadow: 0 0 0 0.25rem rgba(100, 180, 250, 0.3),
    0 0 0 0.125rem #64b4fa;
  --wxc-dropdown--active--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-dropdown--active--outline: none;
  --wxc-dropdown--disabled--background: hsla(0, 0%, 100%, 0.11);
  --wxc-dropdown--disabled--color: hsla(0, 0%, 100%, 0.4);
  --wxc-dropdown--disabled--box-shadow: 0 0 0 2px #64b4fa,
    0px 0px 0px 4px #1170cf;
  --wxc-dropdown--disabled--border: 1px solid hsla(0, 0%, 100%, 0.11);
  --wxc-dropdown--disabled--outline: none;
  --wxc-dropdown--focus--background: #000;
  --wxc-dropdown--focus--color: hsla(0, 0%, 100%, 0.95);
  --wxc-dropdown--focus--box-shadow: 0 0 0 0.25rem rgba(100, 180, 250, 0.3),
    0 0 0 0.125rem #64b4fa;
  --wxc-dropdown--focus--border: 1px solid hsla(0, 0%, 100%, 0.9);
  --wxc-dropdown--focus--outline: none;
  --wxc-input-field--normal--background: transparent;
  --wxc-input-field--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--normal--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-input-field--normal--box-shadow: none;
  --wxc-input-field--normal--outline: none;
  --wxc-input-field--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-input-field--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--hover--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-input-field--hover--box-shadow: none;
  --wxc-input-field--hover--outline: none;
  --wxc-input-field--active--background: hsla(0, 0%, 100%, 0.11);
  --wxc-input-field--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--active--border: 1px solid hsla(0, 0%, 100%, 0.9);
  --wxc-input-field--active--box-shadow: 0 0 0 0.25rem rgba(100, 180, 250, 0.3),
    0 0 0 0.125rem #64b4fa;
  --wxc-input-field--active--outline: none;
  --wxc-input-field--focus--background: hsla(0, 0%, 100%, 0.11);
  --wxc-input-field--focus--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--focus--border: 1px solid hsla(0, 0%, 100%, 0.9);
  --wxc-input-field--focus--box-shadow: 0 0 0 0.25rem rgba(100, 180, 250, 0.3),
    0 0 0 0.125rem #64b4fa;
  --wxc-input-field--focus--outline: none;
  --wxc-input-field--disabled--background: hsla(0, 0%, 100%, 0.11);
  --wxc-input-field--disabled--color: hsla(0, 0%, 100%, 0.11);
  --wxc-input-field--disabled--border: 1px solid transparent;
  --wxc-input-field--disabled--box-shadow: none;
  --wxc-input-field--disabled--outline: none;
  --wxc-input-field--error-static--background: #4f0e10;
  --wxc-input-field--error-static--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--error-static--border: 1px solid #fc8b98;
  --wxc-input-field--error-static--box-shadow: none;
  --wxc-input-field--error-static--outline: none;
  --wxc-input-field--error-hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-input-field--error-hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--error-hover--border: 1px solid #fc8b98;
  --wxc-input-field--error-hover--box-shadow: none;
  --wxc-input-field--error-hover--outline: none;
  --wxc-input-field--error-focus--background: hsla(0, 0%, 100%, 0.11);
  --wxc-input-field--error-focus--color: hsla(0, 0%, 100%, 0.95);
  --wxc-input-field--error-focus--border: 1px solid #fc8b98;
  --wxc-input-field--error-focus--box-shadow: none;
  --wxc-input-field--error-focus--outline: none;
  --wxc-input-icon-color: hsla(0, 0%, 100%, 0.7);
  --wxc-input--label--color: hsla(0, 0%, 100%, 0.95);
  --wxc-options-list--background: #1a1a1a;
  --wxc-options-list--item--focus--box-shadow: 0 0 0 2px #64b4fa inset;
  --wxc-options-list--item--focus--outline: 2px solid rgba(100, 180, 250, 0.3);
  --wxc-options-list--item--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-options-list--item--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-options-list--item--hover--border: none;
  --wxc-options-list--item--active--background: hsla(0, 0%, 100%, 0.11);
  --wxc-options-list--item--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-options-list--item--active--border: none;
  --wxc-options-list--item--selected--background: #1a1a1a;
  --wxc-options-list--item--selected--color: hsla(0, 0%, 100%, 0.95);
  --wxc-options-list--item--selected--border: none;
  --wxc-options-list--checked-icon--color: #64b4fa;
  --wxc-popup--box-shadow: none;
  --wxc-popup--border: none;
  --wxc-scrollbar--thumb: hsla(0, 0%, 100%, 0.11);
  --wxc-scrollbar--background: hsla(0, 0%, 100%, 0.2);
  --wxc-hyperlink--normal--color: #64b4fa;
  --wxc-hyperlink--hover--color: #bfe3ff;
  --wxc-hyperlink--active--color: #bfe3ff;
  --wxc-tooltip-background: #000;
  --wxc-tooltip-border: 1px solid hsla(0, 0%, 100%, 0.2);
  --wxc-tooltip-border-color: hsla(0, 0%, 100%, 0.2);
  --wxc-adaptive-cards--card--border: 1px solid hsla(0, 0%, 100%, 0.2);
  --wxc-adaptive-cards--card--border-radius: 8px;
  --wxc-adaptive-cards--background--default: #1a1a1a;
  --wxc-adaptive-cards--background--emphasis: #3b3b3b;
  --wxc-adaptive-cards--background--accent: #063a75;
  --wxc-adaptive-cards--background--good: #134231;
  --wxc-adaptive-cards--background--attention: #780d13;
  --wxc-adaptive-cards--background--warning: #57330a;
  --wxc-adaptive-cards--color--default: hsla(0, 0%, 100%, 0.95);
  --wxc-adaptive-cards--color--dark: hsla(0, 0%, 100%, 0.95);
  --wxc-adaptive-cards--color--light: hsla(0, 0%, 100%, 0.7);
  --wxc-adaptive-cards--color--accent: #64b4fa;
  --wxc-adaptive-cards--color--good: #3cc29a;
  --wxc-adaptive-cards--color--warning: #f2990a;
  --wxc-adaptive-cards--color--attention: #fc8b98;
  --wxc-adaptive-cards--subtle-color--default: hsla(0, 0%, 100%, 0.7);
  --wxc-adaptive-cards--subtle-color--dark: hsla(0, 0%, 100%, 0.7);
  --wxc-adaptive-cards--subtle-color--light: hsla(0, 0%, 100%, 0.6);
  --wxc-adaptive-cards--subtle-color--accent: #96d0ff;
  --wxc-adaptive-cards--subtle-color--good: #68debd;
  --wxc-adaptive-cards--subtle-color--warning: #ffbe4f;
  --wxc-adaptive-cards--subtle-color--attention: #ffb8c1;
  --wxc-adaptive-cards--highlight-color: #adadad;
  --wxc-adaptive-cards--separator-color: #8f8f8f;
  --wxc-input--title--normal: hsla(0, 0%, 100%, 0.95);
  --wxc-input--title--disabled: hsla(0, 0%, 100%, 0.4);
  --wxc-input--on--normal--border: none;
  --wxc-input--on--normal--background: #64b4fa;
  --wxc-input--on--normal--foreground: rgba(0, 0, 0, 0.95);
  --wxc-input--on--hover--border: none;
  --wxc-input--on--hover--background: #3492eb;
  --wxc-input--on--hover--foreground: rgba(0, 0, 0, 0.95);
  --wxc-input--on--active--border: none;
  --wxc-input--on--active--background: #1170cf;
  --wxc-input--on--active--foreground: rgba(0, 0, 0, 0.95);
  --wxc-input--on--disabled--border: none;
  --wxc-input--on--disabled--background: #063a75;
  --wxc-input--on--disabled--foreground: rgba(0, 0, 0, 0.5);
  --wxc-input--off--normal--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-input--off--normal--background: hsla(0, 0%, 100%, 0.2);
  --wxc-input--off--normal--foreground: hsla(0, 0%, 100%, 0.95);
  --wxc-input--off--hover--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-input--off--hover--background: hsla(0, 0%, 100%, 0.3);
  --wxc-input--off--hover--foreground: hsla(0, 0%, 100%, 0.95);
  --wxc-input--off--active--border: 1px solid hsla(0, 0%, 100%, 0.5);
  --wxc-input--off--active--background: hsla(0, 0%, 100%, 0.4);
  --wxc-input--off--active--foreground: hsla(0, 0%, 100%, 0.95);
  --wxc-input--off--disabled--border: none;
  --wxc-input--off--disabled--background: hsla(0, 0%, 100%, 0.11);
  --wxc-input--off--disabled--foreground: hsla(0, 0%, 100%, 0.4);
  --wxc-date-input--calendar-picker-indicator--filter: invert(5%);
  --wxc-adaptive-cards--action--hover--border: inherit;
  --wxc-adaptive-cards--action--hover--background: hsla(0, 0%, 100%, 0.07);
  --wxc-adaptive-cards--action--hover--foreground: inherit;
  --wxc-adaptive-cards--action--active--border: inherit;
  --wxc-adaptive-cards--action--active--background: hsla(0, 0%, 100%, 0.11);
  --wxc-adaptive-cards--action--active--foreground: inherit;
  --wxc-greeting--description--text-color: hsla(0, 0%, 100%, 0.7);
}
.md-theme-lightWebex,
.wxc-theme-light {
  --wxc-browser-color-scheme: light;
  /*--wxc-loading-logo--url: url(assets/87960965.gif);*/
  --wxc-loading-logo--size: 11.6875rem;
  /*--wxc-auth-logo--url: url(assets/af1a3e22.svg);*/
  --wxc-text-color: rgba(0, 0, 0, 0.95);
  --wxc-secondary-text-color: rgba(0, 0, 0, 0.6);
  --wxc-warning-color: #7d4705;
  --wxc-error-color: #ab0a15;
  --wxc-muted-color: #db1f2e;
  --wxc-speaking-color: #1d805f;
  --wxc-primary-background: linear-gradient(#f0f0f0, #fff);
  --wxc-secondary-background: linear-gradient(#fafafa, #fff);
  --wxc-video-background: #fff;
  --wxc-secondary-video-background: #ededed;
  --wxc-meeting-border: 1px solid rgba(0, 0, 0, 0.2);
  --wxc-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2),
    0 0 0.0625rem rgba(0, 0, 0, 0.12);
  --wxc-focus-box-shadow: 0 0 0 0.25rem rgba(17, 112, 207, 0.3),
    0 0 0 0.125rem #1170cf;
  --wxc-person-status--active-color: #1d805f;
  --wxc-person-status--dnd-color: #db1f2e;
  --wxc-person-status--in-a-meeting-color: #a65e00;
  --wxc-person-status--away-color: #707070;
  --wxc-badge-background: #fff;
  --wxc-badge-border: 0.06rem solid rgba(0, 0, 0, 0.2);
  --wxc-badge-text-color: rgba(0, 0, 0, 0.95);
  --wxc-badge-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16),
    0 0 0.06rem rgba(0, 0, 0, 0.14);
  --wxc-avatar--placeholder-1--background: rgba(0, 0, 0, 0.5);
  --wxc-avatar--placeholder-2--background: #08599c;
  --wxc-avatar--placeholder-3--background: #066070;
  --wxc-avatar--placeholder-4--background: #735107;
  --wxc-avatar--placeholder-5--background: #416116;
  --wxc-avatar--placeholder-6--background: #12615a;
  --wxc-avatar--placeholder-7--background: #914017;
  --wxc-avatar--placeholder-8--background: #a12a3a;
  --wxc-avatar--placeholder-9--background: #535573;
  --wxc-avatar--placeholder-10--background: #643abd;
  --wxc-avatar--placeholder-11--background: #932099;
  --wxc-avatar--bot-status-color: #1170cf;
  --wxc-avatar--self-status-color: #279be8;
  --wxc-avatar--self-status-border: #000;
  --wxc-avatar--text-color: #fff;
  --wxc-banner--top--background: linear-gradient(
    2.25deg,
    hsla(0, 0%, 100%, 0) 1.9%,
    hsla(0, 0%, 100%, 0.3) 50.23%,
    hsla(0, 0%, 100%, 0.8) 98.11%
  );
  --wxc-banner--bottom--background: hsla(0, 0%, 100%, 0.7);
  --wxc-icon--background: #fff;
  --wxc-icon--color: #000;
  --wxc-icon--accent-color: #1d805f;
  --wxc-icon--muted-color: #db1f2e;
  --wxc-icon--error--gradient-start-color: #f0677e;
  --wxc-icon--error--gradient-stop-color: #db1f2e;
  --wxc-modal--background: #fff;
  --wxc-modal--border: 1px solid rgba(0, 0, 0, 0.11);
  --wxc-modal--box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2),
    0 0 0.0625rem rgba(0, 0, 0, 0.12);
  --wxc-button--cancel--normal--background: #db1f2e;
  --wxc-button--cancel--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--cancel--normal--border: none;
  --wxc-button--cancel--active--background: #780d13;
  --wxc-button--cancel--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--cancel--active--border: none;
  --wxc-button--cancel--hover--background: #ab0a15;
  --wxc-button--cancel--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--cancel--hover--border: none;
  --wxc-button--cancel--disabled--background: rgba(0, 0, 0, 0.2);
  --wxc-button--cancel--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-button--cancel--disabled--border: none;
  --wxc-button--default--normal--background: transparent;
  --wxc-button--default--normal--color: rgba(0, 0, 0, 0.95);
  --wxc-button--default--normal--border: 1px solid rgba(0, 0, 0, 0.3);
  --wxc-button--default--active--background: rgba(0, 0, 0, 0.2);
  --wxc-button--default--active--color: rgba(0, 0, 0, 0.95);
  --wxc-button--default--active--border: 1px solid rgba(0, 0, 0, 0.3);
  --wxc-button--default--hover--background: rgba(0, 0, 0, 0.07);
  --wxc-button--default--hover--color: rgba(0, 0, 0, 0.95);
  --wxc-button--default--hover--border: 1px solid rgba(0, 0, 0, 0.3);
  --wxc-button--default--disabled--background: transparent;
  --wxc-button--default--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-button--default--disabled--border: 1px solid rgba(0, 0, 0, 0.2);
  --wxc-button--ghost--normal--background: transparent;
  --wxc-button--ghost--normal--color: rgba(0, 0, 0, 0.95);
  --wxc-button--ghost--normal--border: none;
  --wxc-button--ghost--hover--background: rgba(0, 0, 0, 0.07);
  --wxc-button--ghost--hover--color: rgba(0, 0, 0, 0.95);
  --wxc-button--ghost--hover--border: none;
  --wxc-button--ghost--active--background: rgba(0, 0, 0, 0.2);
  --wxc-button--ghost--active--color: rgba(0, 0, 0, 0.95);
  --wxc-button--ghost--active--border: none;
  --wxc-button--ghost--disabled--background: rgba(0, 0, 0, 0.4);
  --wxc-button--ghost--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-button--ghost--disabled--border: none;
  --wxc-button--join--normal--background: #1d805f;
  --wxc-button--join--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--join--normal--border: none;
  --wxc-button--join--active--background: #134231;
  --wxc-button--join--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--join--active--border: none;
  --wxc-button--join--hover--background: #185e46;
  --wxc-button--join--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--join--hover--border: none;
  --wxc-button--join--disabled--background: rgba(0, 0, 0, 0.2);
  --wxc-button--join--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-button--join--disabled--border: none;
  --wxc-button--primary--normal--background: rgba(0, 0, 0, 0.95);
  --wxc-button--primary--normal--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--primary--normal--border: none;
  --wxc-button--primary--active--background: rgba(0, 0, 0, 0.7);
  --wxc-button--primary--active--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--primary--active--border: none;
  --wxc-button--primary--hover--background: rgba(0, 0, 0, 0.8);
  --wxc-button--primary--hover--color: hsla(0, 0%, 100%, 0.95);
  --wxc-button--primary--hover--border: none;
  --wxc-button--primary--disabled--background: rgba(0, 0, 0, 0.2);
  --wxc-button--primary--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-button--primary--disabled--border: none;
  --wxc-spinner--circle--color: #c9c9c9;
  --wxc-spinner--moving--color: #0f0f0f;
  --wxc-tab--normal--background: transparent;
  --wxc-tab--normal--color: rgba(0, 0, 0, 0.6);
  --wxc-tab--normal--border: none;
  --wxc-tab--active--background: rgba(0, 0, 0, 0.2);
  --wxc-tab--active--color: rgba(0, 0, 0, 0.95);
  --wxc-tab--active--border: none;
  --wxc-tab--hover--background: rgba(0, 0, 0, 0.07);
  --wxc-tab--hover--color: rgba(0, 0, 0, 0.95);
  --wxc-tab--hover--border: none;
  --wxc-dropdown--normal--background: #fff;
  --wxc-dropdown--normal--color: rgba(0, 0, 0, 0.6);
  --wxc-dropdown--normal--box-shadow: none;
  --wxc-dropdown--normal--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-dropdown--normal--outline: none;
  --wxc-dropdown--hover--background: rgba(0, 0, 0, 0.07);
  --wxc-dropdown--hover--color: rgba(0, 0, 0, 0.6);
  --wxc-dropdown--hover--box-shadow: none;
  --wxc-dropdown--hover--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-dropdown--hover--outline: none;
  --wxc-dropdown--active--background: rgba(0, 0, 0, 0.11);
  --wxc-dropdown--active--color: rgba(0, 0, 0, 0.95);
  --wxc-dropdown--active--box-shadow: 0 0 0 0.25rem rgba(17, 112, 207, 0.3),
    0 0 0 0.125rem #1170cf;
  --wxc-dropdown--active--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-dropdown--active--outline: none;
  --wxc-dropdown--disabled--background: rgba(0, 0, 0, 0.11);
  --wxc-dropdown--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-dropdown--disabled--box-shadow: none;
  --wxc-dropdown--disabled--border: 1px solid rgba(0, 0, 0, 0.11);
  --wxc-dropdown--disabled--outline: none;
  --wxc-dropdown--focus--background: #fff;
  --wxc-dropdown--focus--color: rgba(0, 0, 0, 0.95);
  --wxc-dropdown--focus--box-shadow: 0 0 0 0.25rem rgba(17, 112, 207, 0.3),
    0 0 0 0.125rem #1170cf;
  --wxc-dropdown--focus--border: 1px solid rgba(0, 0, 0, 0.9);
  --wxc-dropdown--focus--outline: none;
  --wxc-input-field--normal--background: #fff;
  --wxc-input-field--normal--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--normal--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-input-field--normal--box-shadow: none;
  --wxc-input-field--normal--outline: none;
  --wxc-input-field--hover--background: rgba(0, 0, 0, 0.04);
  --wxc-input-field--hover--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--hover--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-input-field--hover--box-shadow: none;
  --wxc-input-field--hover--outline: none;
  --wxc-input-field--active--background: rgba(0, 0, 0, 0.11);
  --wxc-input-field--active--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--active--border: 1px solid rgba(0, 0, 0, 0.9);
  --wxc-input-field--active--box-shadow: 0 0 0 0.25rem rgba(17, 112, 207, 0.3),
    0 0 0 0.125rem #1170cf;
  --wxc-input-field--active--outline: none;
  --wxc-input-field--focus--background: rgba(0, 0, 0, 0.11);
  --wxc-input-field--focus--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--focus--border: 1px solid rgba(0, 0, 0, 0.9);
  --wxc-input-field--focus--box-shadow: 0 0 0 0.25rem rgba(17, 112, 207, 0.3),
    0 0 0 0.125rem #1170cf;
  --wxc-input-field--focus--outline: none;
  --wxc-input-field--disabled--background: rgba(0, 0, 0, 0.11);
  --wxc-input-field--disabled--color: rgba(0, 0, 0, 0.4);
  --wxc-input-field--disabled--border: 1px solid transparent;
  --wxc-input-field--disabled--box-shadow: none;
  --wxc-input-field--disabled--outline: none;
  --wxc-input-field--error-static--background: #ffe8ea;
  --wxc-input-field--error-static--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--error-static--border: 1px solid #ab0a15;
  --wxc-input-field--error-static--box-shadow: none;
  --wxc-input-field--error-static--outline: none;
  --wxc-input-field--error-hover--background: rgba(0, 0, 0, 0.04);
  --wxc-input-field--error-hover--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--error-hover--border: 1px solid #ab0a15;
  --wxc-input-field--error-hover--box-shadow: none;
  --wxc-input-field--error-hover--outline: none;
  --wxc-input-field--error-focus--background: rgba(0, 0, 0, 0.11);
  --wxc-input-field--error-focus--color: rgba(0, 0, 0, 0.95);
  --wxc-input-field--error-focus--border: 1px solid #ab0a15;
  --wxc-input-field--error-focus--box-shadow: none;
  --wxc-input-field--error-focus--outline: none;
  --wxc-input-icon-color: rgba(0, 0, 0, 0.6);
  --wxc-input--label--color: rgba(0, 0, 0, 0.95);
  --wxc-options-list--background: #fff;
  --wxc-options-list--item--focus--box-shadow: 0 0 0 2px #1170cf inset;
  --wxc-options-list--item--focus--outline: 2px solid rgba(17, 112, 207, 0.3);
  --wxc-options-list--item--hover--background: rgba(0, 0, 0, 0.07);
  --wxc-options-list--item--hover--color: rgba(0, 0, 0, 0.95);
  --wxc-options-list--item--hover--border: none;
  --wxc-options-list--item--active--background: rgba(0, 0, 0, 0.11);
  --wxc-options-list--item--active--color: rgba(0, 0, 0, 0.95);
  --wxc-options-list--item--active--border: none;
  --wxc-options-list--item--selected--background: #fff;
  --wxc-options-list--item--selected--color: rgba(0, 0, 0, 0.95);
  --wxc-options-list--item--selected--border: none;
  --wxc-options-list--checked-icon--color: #1170cf;
  --wxc-popup--box-shadow: none;
  --wxc-popup--border: none;
  --wxc-scrollbar--thumb: rgba(0, 0, 0, 0.11);
  --wxc-scrollbar--background: rgba(0, 0, 0, 0.2);
  --wxc-hyperlink--normal--color: #0353a8;
  --wxc-hyperlink--hover--color: #0a274a;
  --wxc-hyperlink--active--color: #0a274a;
  --wxc-tooltip-background: #fff;
  --wxc-tooltip-border: 1px solid rgba(0, 0, 0, 0.2);
  --wxc-tooltip-border-color: rgba(0, 0, 0, 0.2);
  --wxc-adaptive-cards--card--border: 1px solid rgba(0, 0, 0, 0.2);
  --wxc-adaptive-cards--card--border-radius: 8px;
  --wxc-adaptive-cards--background--default: #fff;
  --wxc-adaptive-cards--background--emphasis: #ededed;
  --wxc-adaptive-cards--background--accent: #dbf0ff;
  --wxc-adaptive-cards--background--good: #cef5eb;
  --wxc-adaptive-cards--background--attention: #ffe8ea;
  --wxc-adaptive-cards--background--warning: #ffebc2;
  --wxc-adaptive-cards--color--default: rgba(0, 0, 0, 0.95);
  --wxc-adaptive-cards--color--dark: rgba(0, 0, 0, 0.95);
  --wxc-adaptive-cards--color--light: rgba(0, 0, 0, 0.6);
  --wxc-adaptive-cards--color--accent: #0353a8;
  --wxc-adaptive-cards--color--good: #185e46;
  --wxc-adaptive-cards--color--attention: #ab0a15;
  --wxc-adaptive-cards--color--warning: #7d4705;
  --wxc-adaptive-cards--subtle-color--default: rgba(0, 0, 0, 0.6);
  --wxc-adaptive-cards--subtle-color--dark: rgba(0, 0, 0, 0.6);
  --wxc-adaptive-cards--subtle-color--light: rgba(0, 0, 0, 0.5);
  --wxc-adaptive-cards--subtle-color--accent: #1170cf;
  --wxc-adaptive-cards--subtle-color--good: #1d805f;
  --wxc-adaptive-cards--subtle-color--warning: #a65e00;
  --wxc-adaptive-cards--subtle-color--attention: #db1f2e;
  --wxc-adaptive-cards--highlight-color: #545454;
  --wxc-adaptive-cards--separator-color: #3b3b3b;
  --wxc-input--title--normal: rgba(0, 0, 0, 0.95);
  --wxc-input--title--disabled: rgba(0, 0, 0, 0.4);
  --wxc-input--on--normal--border: none;
  --wxc-input--on--normal--background: #1170cf;
  --wxc-input--on--normal--foreground: hsla(0, 0%, 100%, 0.95);
  --wxc-input--on--hover--border: none;
  --wxc-input--on--hover--background: #0353a8;
  --wxc-input--on--hover--foreground: hsla(0, 0%, 100%, 0.95);
  --wxc-input--on--active--border: none;
  --wxc-input--on--active--background: #063a75;
  --wxc-input--on--active--foreground: hsla(0, 0%, 100%, 0.95);
  --wxc-input--on--disabled--border: none;
  --wxc-input--on--disabled--background: #bfe3ff;
  --wxc-input--on--disabled--foreground: hsla(0, 0%, 100%, 0.5);
  --wxc-input--off--normal--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-input--off--normal--background: rgba(0, 0, 0, 0.2);
  --wxc-input--off--normal--foreground: rgba(0, 0, 0, 0.95);
  --wxc-input--off--hover--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-input--off--hover--background: rgba(0, 0, 0, 0.3);
  --wxc-input--off--hover--foreground: rgba(0, 0, 0, 0.95);
  --wxc-input--off--active--border: 1px solid rgba(0, 0, 0, 0.5);
  --wxc-input--off--active--background: rgba(0, 0, 0, 0.4);
  --wxc-input--off--active--foreground: rgba(0, 0, 0, 0.95);
  --wxc-input--off--disabled--border: none;
  --wxc-input--off--disabled--background: rgba(0, 0, 0, 0.11);
  --wxc-input--off--disabled--foreground: rgba(0, 0, 0, 0.4);
  --wxc-date-input--calendar-picker-indicator--filter: invert(5%);
  --wxc-adaptive-cards--action--hover--border: inherit;
  --wxc-adaptive-cards--action--hover--background: rgba(0, 0, 0, 0.07);
  --wxc-adaptive-cards--action--hover--foreground: inherit;
  --wxc-adaptive-cards--action--active--border: inherit;
  --wxc-adaptive-cards--action--active--background: rgba(0, 0, 0, 0.11);
  --wxc-adaptive-cards--action--active--foreground: inherit;
  --wxc-greeting--description--text-color: #707070;
}
.wxc {
  font-family: CiscoSansTT Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
  color-scheme: var(--wxc-browser-color-scheme);
}
.wxc,
.wxc * {
  box-sizing: border-box;
}
.wxc * ::-webkit-scrollbar,
.wxc ::-webkit-scrollbar {
  width: 0.25rem;
}
.wxc * ::-webkit-scrollbar-thumb,
.wxc ::-webkit-scrollbar-thumb {
  background: var(--wxc-scrollbar--background);
  border-radius: 0.125rem;
}
.wxc-ac-action__icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.375rem;
}
.wxc-ac-action__right-icon {
  margin-left: 0.375rem;
}
.wxc-ac-container--vertical > .wxc-ac-action:not(:first-child) {
  margin-top: 0.5rem;
}
.wxc-ac-container--horizontal > .wxc-ac-action:not(:first-child) {
  margin-left: 0.5rem;
}
.wxc-adaptive-cards-action-set {
  margin-bottom: 0.5rem;
}
.wxc-ac-adaptive-card {
  background: var(--wxc-primary-background);
  color: var(--wxc-text-color);
  overflow: auto;
  border: var(--wxc-adaptive-cards--card--border);
  border-radius: var(--wxc-adaptive-cards--card--border-radius);
}
.wxc-ac-font-type--monospace {
  font-family: Consolas, Liberation Mono, Courier, monospace;
}
.wxc-ac-font-type--default {
  font-family: CiscoSansTT Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.wxc-ac-container--horizontal.wxc-ac-horizontal-alignment--left {
  justify-content: flex-start;
}
.wxc-ac-container--horizontal.wxc-ac-horizontal-alignment--center {
  justify-content: center;
}
.wxc-ac-container--horizontal.wxc-ac-horizontal-alignment--right {
  justify-content: flex-end;
}
.wxc-ac-container--vertical
  > :not(.wxc-ac-container--horizontal).wxc-ac-horizontal-alignment--left {
  align-self: flex-start;
}
.wxc-ac-container--vertical
  > :not(.wxc-ac-container--horizontal).wxc-ac-horizontal-alignment--center {
  align-self: center;
}
.wxc-ac-container--vertical
  > :not(.wxc-ac-container--horizontal).wxc-ac-horizontal-alignment--right {
  align-self: flex-end;
}
.wxc-ac-size--default {
  font-size: 0.875rem;
}
.wxc-ac-size--small {
  font-size: 0.75rem;
}
.wxc-ac-size--medium {
  font-size: 1rem;
}
.wxc-ac-size--large {
  font-size: 1.25rem;
}
.wxc-ac-size--extraLarge {
  font-size: 1.5rem;
}
.wxc-ac-weight--default {
  font-weight: 400;
}
.wxc-ac-weight--lighter {
  font-weight: lighter;
}
.wxc-ac-weight--bolder {
  font-weight: bolder;
}
.wxc-ac-wrap--false {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wxc-ac-rtl--true {
  direction: rtl;
}
.wxc-ac-rtl--false {
  direction: ltr;
}
.wxc-ac-highlight--true {
  background-color: var(--wxc-adaptive-cards--highlight-color);
}
.wxc-ac-italic--true {
  font-style: italic;
}
.wxc-ac-strikethrough--true {
  text-decoration: line-through;
}
.wxc-ac-underline--true {
  text-decoration: underline;
}
.wxc-ac-container-style--heading {
  font-weight: 700;
  font-size: 200%;
}
.wxc-ac-container-style--default {
  background-color: var(--wxc-adaptive-cards--background--default);
}
.wxc-ac-container-style--emphasis {
  background-color: var(--wxc-adaptive-cards--background--emphasis);
}
.wxc-ac-container-style--good {
  background-color: var(--wxc-adaptive-cards--background--good);
}
.wxc-ac-container-style--attention {
  background-color: var(--wxc-adaptive-cards--background--attention);
}
.wxc-ac-container-style--warning {
  background-color: var(--wxc-adaptive-cards--background--warning);
}
.wxc-ac-container-style--accent {
  background-color: var(--wxc-adaptive-cards--background--accent);
}
.wxc-ac-image-style--person {
  border-radius: 50%;
}
.wxc-ac-image-size--small {
  width: 40px;
  height: 40px;
}
.wxc-ac-image-size--medium {
  width: 80px;
  height: 80px;
}
.wxc-ac-image-size--large {
  width: 160px;
  height: 160px;
}
.wxc-ac-container--has-padding {
  padding: 0.625rem;
}
.wxc-ac-container--horizontal {
  display: flex;
  flex-direction: row;
}
.wxc-ac-container--horizontal > .wxc-ac-width--auto {
  width: auto;
}
.wxc-ac-container--horizontal > .wxc-ac-width--stretch {
  flex: 1;
}
.wxc-ac-container--horizontal > .wxc-ac-height--auto {
  height: auto;
}
.wxc-ac-container--horizontal > .wxc-ac-height--stretch {
  align-self: stretch;
}
.wxc-ac-container--horizontal.wxc-ac-container--has-padding
  > .wxc-ac-bleed--true {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
.wxc-ac-container--horizontal.wxc-ac-container--has-padding
  > .wxc-ac-bleed--true:first-child {
  margin-left: -0.625rem;
}
.wxc-ac-container--horizontal.wxc-ac-container--has-padding
  > .wxc-ac-bleed--true:last-child {
  margin-right: -0.625rem;
}
.wxc-ac-container--vertical {
  display: flex;
  flex-direction: column;
}
.wxc-ac-container--vertical > .wxc-ac-width--auto {
  width: auto;
}
.wxc-ac-container--vertical > .wxc-ac-width--stretch {
  align-self: stretch;
}
.wxc-ac-container--vertical > .wxc-ac-height--auto {
  height: auto;
}
.wxc-ac-container--vertical > .wxc-ac-height--stretch {
  flex: 1;
}
.wxc-ac-container--vertical.wxc-ac-vertical-content-alignment--top {
  justify-content: flex-start;
}
.wxc-ac-container--vertical.wxc-ac-vertical-content-alignment--center {
  justify-content: center;
}
.wxc-ac-container--vertical.wxc-ac-vertical-content-alignment--bottom {
  justify-content: flex-end;
}
.wxc-ac-container--vertical.wxc-ac-container--has-padding
  > .wxc-ac-bleed--true {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.wxc-ac-container--vertical.wxc-ac-container--has-padding
  > .wxc-ac-bleed--true:first-child {
  margin-top: -0.625rem;
}
.wxc-ac-container--vertical.wxc-ac-container--has-padding
  > .wxc-ac-bleed--true:last-child {
  margin-bottom: -0.625rem;
}
.wxc-ac-is-visible--false {
  display: none;
}
.wxc-ac-is-subtle--true {
  color: var(--wxc-adaptive-cards--subtle-color--default);
}
.wxc-ac-is-subtle--true.wxc-ac-color--dark {
  color: var(--wxc-adaptive-cards--subtle-color--dark);
}
.wxc-ac-is-subtle--true.wxc-ac-color--light {
  color: var(--wxc-adaptive-cards--subtle-color--light);
}
.wxc-ac-is-subtle--true.wxc-ac-color--accent {
  color: var(--wxc-adaptive-cards--subtle-color--accent);
}
.wxc-ac-is-subtle--true.wxc-ac-color--good {
  color: var(--wxc-adaptive-cards--subtle-color--good);
}
.wxc-ac-is-subtle--true.wxc-ac-color--warning {
  color: var(--wxc-adaptive-cards--subtle-color--warning);
}
.wxc-ac-is-subtle--true.wxc-ac-color--attention {
  color: var(--wxc-adaptive-cards--subtle-color--attention);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--default {
  color: var(--wxc-adaptive-cards--color--default);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--dark {
  color: var(--wxc-adaptive-cards--color--dark);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--light {
  color: var(--wxc-adaptive-cards--color--light);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--accent {
  color: var(--wxc-adaptive-cards--color--accent);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--good {
  color: var(--wxc-adaptive-cards--color--good);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--warning {
  color: var(--wxc-adaptive-cards--color--warning);
}
:not(.wxc-ac-is-subtle--true).wxc-ac-color--attention {
  color: var(--wxc-adaptive-cards--color--attention);
}
.wxc-ac-background-image {
  background-repeat: no-repeat;
}
.wxc-ac-background-image-fill-mode--repeat {
  background-repeat: repeat;
}
.wxc-ac-background-image-fill-mode--repeathorizontally {
  background-repeat: repeat-x;
}
.wxc-ac-background-image-fill-mode--repeatvertically {
  background-repeat: repeat-y;
}
.wxc-ac-background-image-fill-mode--cover {
  background-size: cover;
}
.wxc-ac-background-image-vertical-alignment--top {
  background-position: top;
}
.wxc-ac-background-image-vertical-alignment--center {
  background-position: 50%;
}
.wxc-ac-background-image-vertical-alignment--bottom {
  background-position: bottom;
}
.wxc-ac-background-image-horizontal-alignment--left {
  background-position: 0;
}
.wxc-ac-background-image-horizontal-alignment--center {
  background-position: 50%;
}
.wxc-ac-background-image-horizontal-alignment--right {
  background-position: 100%;
}
.wxc-ac-select-action:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-ac-select-action:hover {
  border: var(--wxc-adaptive-cards--action--hover--border);
  background-color: var(--wxc-adaptive-cards--action--hover--background);
  color: var(--wxc-adaptive-cards--action--hover--foreground);
}
.wxc-ac-select-action:active {
  border: var(--wxc-adaptive-cards--action--active--border);
  background-color: var(--wxc-adaptive-cards--action--active--background);
  color: var(--wxc-adaptive-cards--action--active--foreground);
}
.wxc-adaptive-cards-unknown-component {
  white-space: pre-wrap;
}
.wxc-adaptive-cards-image-set {
  display: flex;
  flex-wrap: wrap;
}
.wxc-adaptive-cards-input-text {
  display: flex;
}
.wxc-adaptive-cards-input-text .wxc-adaptive-cards-input-text__inline-action {
  margin-left: 0.5rem;
}
.wxc-adaptive-cards-input-text .wxc-adaptive-cards-input-text__input,
.wxc-adaptive-cards-input-text .wxc-adaptive-cards-input-text__textbox {
  flex: 1;
}
.wxc-adaptive-cards-input-time .wxc-adaptive-cards-input-time__body {
  display: flex;
  align-items: center;
}
.wxc-adaptive-cards-input-time .wxc-adaptive-cards-input-time__separator {
  margin: 0 0.313rem;
}
.wxc-adaptive-cards-fact-set .wxc-adaptive-cards-fact-set__fact-title {
  padding-right: 0.5rem;
  text-align: left;
}
.wxc-markdown ol,
.wxc-markdown p,
.wxc-markdown ul {
  margin: 0;
}
.wxc-markdown a {
  color: var(--wxc-hyperlink--normal--color);
  text-decoration: underline;
}
.wxc-markdown a:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-markdown a:hover {
  cursor: pointer;
  color: var(--wxc-hyperlink--hover--color);
}
.wxc-markdown a:active {
  cursor: pointer;
  color: var(--wxc-hyperlink--active--color);
}
.wxc-ac-spacer {
  flex-shrink: 0;
}
.wxc-ac-container--vertical > .wxc-ac-spacer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wxc-ac-container--vertical > .wxc-ac-spacer:first-child {
  display: none;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--default {
  height: 0.5rem;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--none {
  height: 0;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--small {
  height: 0.188rem;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--medium {
  height: 1.125rem;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--large {
  height: 1.875rem;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--extralarge {
  height: 2.5rem;
}
.wxc-ac-container--vertical > .wxc-ac-spacer--spacing--padding {
  height: 0.625rem;
}
.wxc-ac-container--vertical > .wxc-ac-spacer > :first-child {
  height: 0.063rem;
  background-color: var(--wxc-adaptive-cards--separator-color);
  align-self: stretch;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer:first-child {
  display: none;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--default {
  width: 0.5rem;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--none {
  width: 0;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--small {
  width: 0.188rem;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--medium {
  width: 1.125rem;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--large {
  width: 1.875rem;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--extralarge {
  width: 2.5rem;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer--spacing--padding {
  width: 0.625rem;
}
.wxc-ac-container--horizontal > .wxc-ac-spacer > :first-child {
  width: 0.063rem;
  background-color: var(--wxc-adaptive-cards--separator-color);
  align-self: stretch;
}
.wxc-adaptive-cards-text-block--max-lines {
  display: -webkit-box;
  flex-shrink: 0;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.wxc-badge {
  padding: 0.5rem 1rem 0.4375rem 1.0625rem;
  color: var(--wxc-badge-text-color);
  background: var(--wxc-badge-background);
  border: var(--wxc-badge-border);
  box-shadow: var(--wxc-badge-box-shadow);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
}
.wxc-banner {
  display: flex;
  justify-content: center;
  color: var(--wxc-text-color);
  position: absolute;
  right: 0;
  left: 0;
}
.wxc-banner--top {
  top: 0;
  background: var(--wxc-banner--top--background);
  padding: 0.75rem 0 5.25rem;
}
.wxc-banner--bottom {
  bottom: 0;
  background: var(--wxc-banner--bottom--background);
  padding: 0.75rem 0;
}
.wxc-button {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  font-family: CiscoSansTT Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1;
  padding: 0 0.75rem;
}
.wxc-button:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-button:not([wxc-disabled]) {
  cursor: pointer;
}
.wxc-button--join {
  background: var(--wxc-button--join--normal--background);
  border: var(--wxc-button--join--normal--border);
  color: var(--wxc-button--join--normal--color);
}
.wxc-button--join:hover {
  background: var(--wxc-button--join--hover--background);
  color: var(--wxc-button--join--hover--color);
  border: var(--wxc-button--join--hover--border);
}
.wxc-button--join.wxc-button--pressed,
.wxc-button--join:active {
  background: var(--wxc-button--join--active--background);
  color: var(--wxc-button--join--active--color);
  border: var(--wxc-button--join--active--border);
}
.wxc-button--join[wxc-disabled] {
  background: var(--wxc-button--join--disabled--background);
  color: var(--wxc-button--join--disabled--color);
  border: var(--wxc-button--join--disabled--border);
}
.wxc-button--default,
.wxc-button--toggle {
  background: var(--wxc-button--default--normal--background);
  color: var(--wxc-button--default--normal--color);
  border: var(--wxc-button--default--normal--border);
}
.wxc-button--default:hover,
.wxc-button--toggle:hover {
  background: var(--wxc-button--default--hover--background);
  color: var(--wxc-button--default--hover--color);
  border: var(--wxc-button--default--hover--border);
}
.wxc-button--default.wxc-button--pressed,
.wxc-button--default:active,
.wxc-button--toggle.wxc-button--pressed,
.wxc-button--toggle:active {
  background: var(--wxc-button--default--active--background);
  color: var(--wxc-button--default--active--color);
  border: var(--wxc-button--default--active--border);
}
.wxc-button--default[wxc-disabled],
.wxc-button--toggle[wxc-disabled] {
  background: var(--wxc-button--default--disabled--background);
  color: var(--wxc-button--default--disabled--color);
  border: var(--wxc-button--default--disabled--border);
}
.wxc-button--cancel {
  background: var(--wxc-button--cancel--normal--background);
  color: var(--wxc-button--cancel--normal--color);
  border: var(--wxc-button--cancel--normal--border);
}
.wxc-button--cancel:hover {
  background: var(--wxc-button--cancel--hover--background);
  color: var(--wxc-button--cancel--hover--color);
  border: var(--wxc-button--cancel--hover--border);
}
.wxc-button--cancel.wxc-button--pressed,
.wxc-button--cancel:active {
  background: var(--wxc-button--cancel--active--background);
  color: var(--wxc-button--cancel--active--color);
  border: var(--wxc-button--cancel--active--border);
}
.wxc-button--cancel[wxc-disabled] {
  background: var(--wxc-button--cancel--disabled--background);
  color: var(--wxc-button--cancel--disabled--color);
  border: var(--wxc-button--cancel--disabled--border);
}
.wxc-button--ghost {
  background: var(--wxc-button--ghost--normal--background);
  color: var(--wxc-button--ghost--normal--color);
  border: var(--wxc-button--ghost--normal--border);
  border-radius: 50%;
  padding: 0 0.375rem;
}
.wxc-button--ghost:hover {
  background: var(--wxc-button--ghost--hover--background);
  color: var(--wxc-button--ghost--hover--color);
  border: var(--wxc-button--ghost--hover--border);
}
.wxc-button--ghost:active {
  background: var(--wxc-button--ghost--active--background);
  color: var(--wxc-button--ghost--active--color);
  border: var(--wxc-button--ghost--active--border);
}
.wxc-button--ghost[wxc-disabled] {
  background: var(--wxc-button--ghost--disabled--background);
  color: var(--wxc-button--ghost--disabled--color);
  border: var(--wxc-button--ghost--disabled--border);
}
.wxc-button--primary {
  font-size: 0.938rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.875rem;
  letter-spacing: 0.015rem;
  background: var(--wxc-button--primary--normal--background);
  color: var(--wxc-button--primary--normal--color);
  border: var(--wxc-button--primary--normal--border);
}
.wxc-button--primary:hover {
  background: var(--wxc-button--primary--hover--background);
  color: var(--wxc-button--primary--hover--color);
  border: var(--wxc-button--primary--hover--border);
}
.wxc-button--primary.wxc-button--pressed,
.wxc-button--primary:active {
  background: var(--wxc-button--primary--active--background);
  color: var(--wxc-button--primary--active--color);
  border: var(--wxc-button--primary--active--border);
}
.wxc-button--primary[wxc-disabled] {
  background: var(--wxc-button--primary--disabled--background);
  color: var(--wxc-button--primary--disabled--color);
  border: var(--wxc-button--primary--disabled--border);
}
.wxc-input-field {
  flex-direction: column;
}
.wxc-input-field,
.wxc-input-field .wxc-input-field__form-control {
  display: flex;
  position: relative;
}
.wxc-input-field .wxc-input-field__input {
  font-family: CiscoSansTT Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
  background: var(--wxc-input-field--normal--background);
  border: var(--wxc-input-field--normal--border);
  color: var(--wxc-input-field--normal--color);
  border-radius: 0.5rem;
  padding: 0.313rem 0.75rem 0.063rem;
  width: 100%;
  height: 2rem;
  -webkit-appearance: none;
}
.wxc-input-field .wxc-input-field__input[type="number"] {
  -moz-appearance: textfield;
}
.wxc-input-field
  .wxc-input-field__input[type="number"]::-webkit-inner-spin-button,
.wxc-input-field
  .wxc-input-field__input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.wxc-input-field
  .wxc-input-field__input[type="number"]:hover::-webkit-inner-spin-button,
.wxc-input-field
  .wxc-input-field__input[type="number"]:hover::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.wxc-input-field .wxc-input-field__input:disabled {
  background: var(--wxc-input-field--disabled--background);
  border: var(--wxc-input-field--disabled--border);
  color: var(--wxc-input-field--disabled--color);
  box-shadow: var(--wxc-input-field--disabled--box-shadow);
  outline: var(--wxc-input-field--disabled--outline);
}
.wxc-input-field
  .wxc-input-field__input:not(:disabled):not(:focus):not(:hover) {
  background: var(--wxc-input-field--normal--background);
  border: var(--wxc-input-field--normal--border);
  color: var(--wxc-input-field--normal--color);
  box-shadow: --wxc-input-field--normal--box-shadow;
  outline: var(--wxc-input-field--normal--outline);
}
.wxc-input-field .wxc-input-field__input:not(:disabled):not(:focus):hover {
  background: var(--wxc-input-field--hover--background);
  border: var(--wxc-input-field--hover--border);
  color: var(--wxc-input-field--hover--color);
  box-shadow: var(--wxc-input-field--hover--box-shadow);
  outline: var(--wxc-input-field--hover--outline);
}
.wxc-input-field .wxc-input-field__input:not(:disabled):focus {
  background: var(--wxc-input-field--focus--background);
  border: var(--wxc-input-field--focus--border);
  color: var(--wxc-input-field--focus--color);
  box-shadow: var(--wxc-input-field--focus--box-shadow);
  outline: var(--wxc-input-field--focus--outline);
}
.wxc-input-field
  .wxc-input-field__input:not(:disabled).wxc-input-field__error:not(:focus):not(:hover) {
  background: var(--wxc-input-field--error-static--background);
  border: var(--wxc-input-field--error-static--border);
  color: var(--wxc-input-field--error-static--color);
  box-shadow: var(--wxc-input-field--error-static--box-shadow);
  outline: var(--wxc-input-field--error-static--outline);
}
.wxc-input-field
  .wxc-input-field__input:not(:disabled).wxc-input-field__error:not(:focus):hover {
  background: var(--wxc-input-field--error-hover--background);
  border: var(--wxc-input-field--error-hover--border);
  color: var(--wxc-input-field--error-hover--color);
  box-shadow: var(--wxc-input-field--error-hover--box-shadow);
  outline: var(--wxc-input-field--error-hover--outline);
}
.wxc-input-field
  .wxc-input-field__input:not(:disabled).wxc-input-field__error:focus {
  background: var(--wxc-input-field--error-focus--background);
  border: var(--wxc-input-field--error-focus--border);
  color: var(--wxc-input-field--error-focus--color);
  box-shadow: var(--wxc-input-field--error-focus--box-shadow);
  outline: var(--wxc-input-field--error-focus--outline);
}
.wxc-input-field .wxc-input-field__right-icon {
  position: absolute;
  color: var(--wxc-input-icon-color);
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}
.wxc-input-field .wxc-input-field__right-icon:not(:last-child) {
  right: 2rem;
}
.wxc-input-field--has-right-icon .wxc-input-field__input {
  padding-right: 1.875rem;
}
.wxc-input-field--has-right-controls .wxc-input-field__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.wxc-loader {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.wxc-loader .wxc-loader__bullet {
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  margin: 1rem auto;
  border-radius: 0.3125rem;
  animation: dotFlashing 1s linear infinite alternate;
  animation-delay: 0.5s;
}
.wxc-loader .wxc-loader__bullet:after,
.wxc-loader .wxc-loader__bullet:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.wxc-loader .wxc-loader__bullet:before {
  left: -0.9375rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.3125rem;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}
.wxc-loader .wxc-loader__bullet:after {
  left: 0.9375rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.3125rem;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dotFlashing {
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50%,
  to {
    background-color: #ededed;
  }
}
.wxc-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}
.wxc-modal .wxc-modal__content {
  border: var(--wxc-modal--border);
  box-shadow: var(--wxc-modal--box-shadow);
  background-color: var(--wxc-modal--background);
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.wxc-modal--centered-modal {
  align-items: center;
}
.wxc-modal--centered-modal .wxc-modal__content {
  flex: 0 1 auto;
}
.wxc-modal .wxc-modal__header {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
}
.wxc-modal .wxc-modal__title {
  color: var(--wxc-text-color);
  font-family: CiscoSansTT Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}
.wxc-modal .wxc-modal__close {
  margin-left: auto;
}
.wxc-modal .wxc-modal__back {
  padding: 0.475rem;
}
.wxc-modal .wxc-modal__body {
  padding: 0 1.25rem 1.25rem;
  overflow-y: auto;
  flex: 1;
  min-height: 5rem;
  margin-bottom: 1rem;
}
.wxc-option {
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--wxc-text-color);
}
.wxc-option .wxc-option__label {
  flex: 1;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wxc-option .wxc-option__check {
  color: var(--wxc-options-list--checked-icon--color);
  margin-left: 0.25rem;
}
.wxc-option .wxc-option__check--invisible {
  visibility: hidden;
}
.wxc-option[aria-selected="true"] {
  background: var(--wxc-options-list--item--selected--background);
  color: var(--wxc-options-list--item--selected--color);
  border: var(--wxc-options-list--item--selected--border);
}
.wxc-option:hover {
  background: var(--wxc-options-list--item--hover--background);
  color: var(--wxc-options-list--item--hover--color);
  border: var(--wxc-options-list--item--hover--border);
}
.wxc-option:active {
  background: var(--wxc-options-list--item--active--background);
  color: var(--wxc-options-list--item--active--color);
  border: var(--wxc-options-list--item--active--border);
}
.wxc-option:focus-visible {
  outline: var(--wxc-options-list--item--focus--outline);
  box-shadow: var(--wxc-options-list--item--focus--box-shadow);
}
.wxc-options-list {
  list-style: none;
  background: var(--wxc-options-list--background);
  margin: 0;
  padding: 0.5rem;
}
.wxc-popup {
  position: fixed;
  z-index: 1;
  border: var(--wxc-popup--border);
  border-radius: 0.75rem;
  box-shadow: var(--wxc-popup--box-shadow);
  overflow: auto;
}
.wxc-spinner {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--wxc-spinner--circle--color);
  border-top-color: var(--wxc-spinner--moving--color);
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
.wxc-title {
  color: var(--wxc-text-color);
  line-height: 150%;
  margin: 1.25rem 0 0.25rem;
  font-weight: 400;
}
.wxc-title--section {
  font-family: CiscoSansTT Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.wxc-title--subsection {
  font-family: CiscoSansTT Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.wxc-tooltip-container {
  position: absolute;
  min-width: 4rem;
  max-width: 12.5rem;
  display: block;
  transform: translateX(-50%);
  z-index: 100;
  bottom: 0;
}
.wxc-tooltip-container .wxc-tooltip-container__wrapper {
  position: relative;
}
.wxc-tooltip-container .wxc-tooltip-container__box {
  background: var(--wxc-tooltip-background);
  border-radius: 0.4375rem;
  border: var(--wxc-tooltip-border);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16),
    0 0 0.0625rem rgba(0, 0, 0, 0.14);
  color: var(--wxc-text-color);
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.5rem 0.75rem 0.585625rem;
  text-align: center;
  position: relative;
}
.wxc-tooltip-container
  .wxc-tooltip-container__box
  .wxc-tooltip-container__arrow {
  position: absolute;
  border: var(--wxc-tooltip-border);
  left: 50%;
  transform: translateX(-50%) rotate(45deg) skew(5deg, 5deg);
  bottom: -0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  background: var(--wxc-tooltip-background);
  border-radius: 0.25rem;
  border-left-color: transparent;
  border-bottom-color: var(--wxc-tooltip-border-color);
  border-right-color: var(--wxc-tooltip-border-color);
  border-top-color: transparent;
}
.wxc-audio-microphone-muted-icon path,
.wxc-camera-muted-icon path {
  fill: var(--wxc-icon--muted-color);
}
.wxc-icon {
  fill: currentColor;
}
:disabled .wxc-icon path,
[wxc-disabled] .wxc-icon path {
  fill: currentColor;
}
.wxc-icon .accent {
  fill: var(--wxc-icon--accent-color);
}
:disabled .wxc-icon .accent,
[wxc-disabled] .wxc-icon .accent {
  display: none;
}
.wxc-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.wxc-checkbox:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-checkbox .wxc-checkbox__title {
  color: var(--wxc-text-color);
  margin-left: 0.5rem;
}
.wxc-checkbox .wxc-checkbox__box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  padding: 0.25rem;
  border-radius: 0.125rem;
}
.wxc-checkbox .wxc-checkbox__box .wxc-checkbox__checkmark {
  width: 9px;
  height: 6.5px;
}
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--checked,
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--indeterminate {
  background-color: var(--wxc-input--on--normal--background);
  border: var(--wxc-input--on--normal--border);
}
.wxc-checkbox
  .wxc-checkbox__box--enabled.wxc-checkbox__box--checked
  .wxc-checkbox__checkmark,
.wxc-checkbox
  .wxc-checkbox__box--enabled.wxc-checkbox__box--indeterminate
  .wxc-checkbox__checkmark {
  color: var(--wxc-input--on--normal--foreground);
}
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--checked:hover,
.wxc-checkbox
  .wxc-checkbox__box--enabled.wxc-checkbox__box--indeterminate:hover {
  background-color: var(--wxc-input--on--hover--background);
  border: var(--wxc-input--on--hover--border);
}
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--checked:active,
.wxc-checkbox
  .wxc-checkbox__box--enabled.wxc-checkbox__box--indeterminate:active {
  background-color: var(--wxc-input--on--active--background);
  border: var(--wxc-input--on--active--border);
}
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--unchecked {
  background-color: var(--wxc-input--off--normal--background);
  border: var(--wxc-input--off--normal--border);
}
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--unchecked:hover {
  background-color: var(--wxc-input--off--hover--background);
  border: var(--wxc-input--off--hover--border);
}
.wxc-checkbox .wxc-checkbox__box--enabled.wxc-checkbox__box--unchecked:active {
  background-color: var(--wxc-input--off--active--background);
  border: var(--wxc-input--off--active--border);
}
.wxc-checkbox .wxc-checkbox__box--disabled {
  cursor: default;
}
.wxc-checkbox .wxc-checkbox__box--disabled.wxc-checkbox__box--checked {
  background-color: var(--wxc-input--on--disabled--background);
  border: var(--wxc-input--on--disabled--border);
}
.wxc-checkbox
  .wxc-checkbox__box--disabled.wxc-checkbox__box--checked
  .wxc-checkbox__checkmark {
  color: var(--wxc-input--on--disabled--foreground);
}
.wxc-checkbox .wxc-checkbox__box--disabled.wxc-checkbox__box--unchecked {
  background-color: var(--wxc-input--off--disabled--background);
  border: var(--wxc-input--off--disabled--border);
}
.wxc-date-input input[type="date"] {
  position: relative;
  padding-left: 2rem;
}
.wxc-date-input input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  margin: 0 0 0.25rem;
  padding: 0;
  left: 0.75rem;
  filter: var(--wxc-date-input--calendar-picker-indicator--filter);
}
.wxc-dropdown__control {
  position: relative;
}
.wxc-dropdown .wxc-dropdown__selected-option {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
}
.wxc-dropdown .wxc-dropdown__selected-option .wxc-dropdown__label {
  flex: 1;
  display: block;
  margin-right: 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wxc-dropdown:not(.wxc-dropdown--disabled) .wxc-dropdown__selected-option {
  background: var(--wxc-dropdown--normal--background);
  border: var(--wxc-dropdown--normal--border);
  box-shadow: var(--wxc-dropdown--normal--box-shadow);
  color: var(--wxc-dropdown--normal--color);
  outline: var(--wxc-dropdown--normal--outline);
  cursor: pointer;
}
.wxc-dropdown:not(.wxc-dropdown--disabled)
  .wxc-dropdown__selected-option.wxc-dropdown__expanded {
  background: var(--wxc-dropdown--active--background);
  border: var(--wxc-dropdown--active--border);
  box-shadow: var(--wxc-dropdown--active--box-shadow);
  color: var(--wxc-dropdown--active--color);
  outline: var(--wxc-dropdown--active--outline);
}
.wxc-dropdown:not(.wxc-dropdown--disabled)
  .wxc-dropdown__selected-option.wxc-dropdown__expanded:focus {
  background: var(--wxc-dropdown--active--background);
}
.wxc-dropdown:not(.wxc-dropdown--disabled)
  .wxc-dropdown__selected-option:hover {
  background: var(--wxc-dropdown--hover--background);
  border: var(--wxc-dropdown--hover--border);
  box-shadow: var(--wxc-dropdown--hover--box-shadow);
  color: var(--wxc-dropdown--hover--color);
  outline: var(--wxc-dropdown--hover--outline);
}
.wxc-dropdown:not(.wxc-dropdown--disabled)
  .wxc-dropdown__selected-option:active {
  background: var(--wxc-dropdown--active--background);
  border: var(--wxc-dropdown--active--border);
  box-shadow: var(--wxc-dropdown--active--box-shadow);
  color: var(--wxc-dropdown--active--color);
  outline: var(--wxc-dropdown--active--outline);
}
.wxc-dropdown:not(.wxc-dropdown--disabled)
  .wxc-dropdown__selected-option:focus {
  background: var(--wxc-dropdown--focus--background);
  border: var(--wxc-dropdown--focus--border);
  box-shadow: var(--wxc-dropdown--focus--box-shadow);
  color: var(--wxc-dropdown--focus--color);
  outline: var(--wxc-dropdown--focus--outline);
}
.wxc-dropdown--disabled .wxc-dropdown__selected-option {
  background: var(--wxc-dropdown--disabled--background);
  border: var(--wxc-dropdown--disabled--border);
  box-shadow: var(--wxc-dropdown--disabled--box-shadow);
  color: var(--wxc-dropdown--disabled--color);
  outline: var(--wxc-dropdown--disabled--outline);
  cursor: default;
}
.wxc-label {
  display: flex;
  flex-direction: column;
}
.wxc-label__label-text {
  align-self: flex-start;
  color: var(--wxc-text-color);
  font-size: 0.875rem;
  margin: 0 0 0.25rem 0.8125rem;
  white-space: nowrap;
}
.wxc-label__text {
  white-space: normal;
}
.wxc-label__required {
  color: var(--wxc-error-color);
}
.wxc-label__control {
  flex: 1;
}
.wxc-label__error {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--wxc-error-color);
  margin: 0.25rem 0;
}
.wxc-label__error .wxc-label__error-text {
  font-size: 0.875rem;
}
.wxc-label__error .wxc-label__error-icon {
  margin: 0 0.5625rem 0 0.8125rem;
}
.wxc-number-input .wxc-number-input__controls {
  height: 100%;
  width: 1.9375rem;
  display: flex;
  flex-direction: column;
  border-left: var(--wxc-input-field--normal--border);
  right: 0.0625rem;
}
.wxc-number-input .wxc-number-input__increment-button {
  border: var(--wxc-input-field--normal--border);
  border-radius: 0 0.5rem 0 0;
  border-left-color: transparent;
  position: relative;
  left: -1px;
}
.wxc-number-input .wxc-number-input__decrement-button {
  border: var(--wxc-input-field--normal--border);
  border-radius: 0 0 0.5rem 0;
  border-left-color: transparent;
  border-top-color: transparent;
  position: relative;
  left: -1px;
}
.wxc-number-input .wxc-number-input__decrement-button,
.wxc-number-input .wxc-number-input__increment-button {
  flex-basis: 50%;
}
.wxc-number-input .wxc-number-input__decrement-button:active,
.wxc-number-input .wxc-number-input__increment-button:active {
  background: var(--wxc-input-field--active--background);
  border: var(--wxc-input-field--active--border);
  color: var(--wxc-input-field--active--color);
  box-shadow: var(--wxc-input-field--active--box-shadow);
}
.wxc-number-input .wxc-number-input__decrement-button:disabled,
.wxc-number-input .wxc-number-input__increment-button:disabled {
  background: var(--wxc-input-field--disabled--background);
  border: var(--wxc-input-field--disabled--border);
  color: var(--wxc-input-field--disabled--color);
  box-shadow: var(--wxc-input-field--disabled--box-shadow);
}
.wxc-radio-button {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.wxc-radio-button:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-radio-button__title {
  color: var(--wxc-input--title--normal);
  margin-left: 0.5rem;
}
.wxc-radio-button__background {
  position: relative;
  display: inline-block;
  content: "";
  cursor: pointer;
  border-radius: 50%;
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
}
.wxc-radio-button__foreground {
  position: absolute;
  display: inline-block;
  content: "";
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
  top: 0.3rem;
  left: 0.3rem;
}
.wxc-radio-button[aria-disabled="false"][aria-checked="false"]
  .wxc-radio-button__background {
  border: var(--wxc-input--off--normal--border);
  background-color: var(--wxc-input--off--normal--background);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="false"]
  .wxc-radio-button__background:hover {
  border: var(--wxc-input--off--hover--border);
  background-color: var(--wxc-input--off--hover--background);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="false"]
  .wxc-radio-button__background:active {
  border: var(--wxc-input--off--active--border);
  background-color: var(--wxc-input--off--active--background);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="true"]
  .wxc-radio-button__background {
  border: var(--wxc-input--on--normal--border);
  background-color: var(--wxc-input--on--normal--background);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="true"]
  .wxc-radio-button__background
  .wxc-radio-button__foreground {
  background-color: var(--wxc-input--on--normal--foreground);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="true"]
  .wxc-radio-button__background:hover {
  border: var(--wxc-input--on--hover--border);
  background-color: var(--wxc-input--on--hover--background);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="true"]
  .wxc-radio-button__background:hover
  .wxc-radio-button__foreground {
  background-color: var(--wxc-input--on--hover--foreground);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="true"]
  .wxc-radio-button__background:active {
  border: var(--wxc-input--on--active--border);
  background-color: var(--wxc-input--on--active--background);
}
.wxc-radio-button[aria-disabled="false"][aria-checked="true"]
  .wxc-radio-button__background:active
  .wxc-radio-button__foreground {
  background-color: var(--wxc-input--on--active--foreground);
}
.wxc-radio-button[aria-disabled="true"] {
  cursor: default;
}
.wxc-radio-button[aria-disabled="true"] .wxc-radio-button__title {
  color: var(--wxc-input--title--disabled);
}
.wxc-radio-button[aria-disabled="true"][aria-checked="false"]
  .wxc-radio-button__background {
  border: var(--wxc-input--off--disabled--border);
  background-color: var(--wxc-input--off--disabled--background);
}
.wxc-radio-button[aria-disabled="true"][aria-checked="true"]
  .wxc-radio-button__background {
  border: var(--wxc-input--on--disabled--border);
  background-color: var(--wxc-input--on--disabled--background);
}
.wxc-radio-button[aria-disabled="true"][aria-checked="true"]
  .wxc-radio-button__background
  .wxc-radio-button__foreground {
  background-color: var(--wxc-input--on--disabled--foreground);
}
.wxc-textbox__control {
  font-family: CiscoSansTT Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
  width: 100%;
  height: 100%;
  background: var(--wxc-input-field--normal--background);
  color: var(--wxc-input-field--normal--color);
  border: var(--wxc-input-field--normal--border);
  box-shadow: var(--wxc-input-field--normal--box-shadow);
  outline: var(--wxc-input-field--normal--outline);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  text-align: left;
  resize: none;
  -webkit-appearance: none;
}
.wxc-textbox__control:hover {
  background: var(--wxc-input-field--hover--background);
  color: var(--wxc-input-field--hover--color);
  border: var(--wxc-input-field--hover--border);
  box-shadow: var(--wxc-input-field--hover--box-shadow);
  outline: var(--wxc-input-field--hover--outline);
}
.wxc-textbox__control:active {
  background: var(--wxc-input-field--active--background);
  color: var(--wxc-input-field--active--color);
  border: var(--wxc-input-field--active--border);
  box-shadow: var(--wxc-input-field--active--box-shadow);
  outline: var(--wxc-input-field--active--outline);
}
.wxc-textbox__control:focus {
  background: var(--wxc-input-field--focus--background);
  color: var(--wxc-input-field--focus--color);
  border: var(--wxc-input-field--focus--border);
  box-shadow: var(--wxc-input-field--focus--box-shadow);
  outline: var(--wxc-input-field--focus--outline);
}
.wxc-textbox__control:disabled {
  background: var(--wxc-input-field--disabled--background);
  border: var(--wxc-input-field--disabled--border);
  color: var(--wxc-input-field--disabled--color);
  box-shadow: var(--wxc-input-field--disabled--box-shadow);
  outline: var(--wxc-input-field--disabled--outline);
}
.wxc-time-input input[type="time"] {
  position: relative;
  padding-left: 2rem;
}
.wxc-time-input input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  margin: 0 0 0.25rem;
  padding: 0;
  left: 0.75rem;
  filter: var(--wxc-date-input--calendar-picker-indicator--filter);
}
.wxc-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.wxc-toggle .wxc-toggle__title {
  color: var(--wxc-text-color);
  margin-left: 0.5rem;
}
.wxc-toggle .wxc-toggle__switch {
  position: relative;
  width: 2.75rem;
  height: 1.375rem;
  padding: 0.375rem;
  border-radius: 0.688rem;
}
.wxc-toggle .wxc-toggle__switch:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-toggle .wxc-toggle__switch .wxc-toggle__slider {
  position: absolute;
  width: 22.73%;
  height: 45.46%;
  top: 27.37%;
  border-radius: 50%;
}
.wxc-toggle .wxc-toggle__switch--on .wxc-toggle__slider {
  left: 63.64%;
}
.wxc-toggle .wxc-toggle__switch--off .wxc-toggle__slider {
  left: 13.63%;
}
.wxc-toggle .wxc-toggle__switch--enabled.wxc-toggle__switch--on {
  background-color: var(--wxc-input--on--normal--background);
}
.wxc-toggle
  .wxc-toggle__switch--enabled.wxc-toggle__switch--on
  .wxc-toggle__slider {
  background-color: var(--wxc-input--on--normal--foreground);
}
.wxc-toggle .wxc-toggle__switch--enabled.wxc-toggle__switch--on:hover {
  background-color: var(--wxc-input--on--hover--background);
}
.wxc-toggle .wxc-toggle__switch--enabled.wxc-toggle__switch--on:active {
  background-color: var(--wxc-input--on--active--background);
}
.wxc-toggle .wxc-toggle__switch--enabled.wxc-toggle__switch--off {
  background-color: var(--wxc-input--off--normal--background);
}
.wxc-toggle
  .wxc-toggle__switch--enabled.wxc-toggle__switch--off
  .wxc-toggle__slider {
  background-color: var(--wxc-input--off--normal--foreground);
}
.wxc-toggle .wxc-toggle__switch--enabled.wxc-toggle__switch--off:hover {
  background-color: var(--wxc-input--off--hover--background);
}
.wxc-toggle .wxc-toggle__switch--enabled.wxc-toggle__switch--off:active {
  background-color: var(--wxc-input--off--active--background);
}
.wxc-toggle .wxc-toggle__switch--disabled {
  cursor: default;
}
.wxc-toggle .wxc-toggle__switch--disabled.wxc-toggle__switch--on {
  background-color: var(--wxc-input--on--disabled--background);
}
.wxc-toggle
  .wxc-toggle__switch--disabled.wxc-toggle__switch--on
  .wxc-toggle__slider {
  background-color: var(--wxc-input--on--disabled--foreground);
}
.wxc-toggle .wxc-toggle__switch--disabled.wxc-toggle__switch--off {
  background-color: var(--wxc-input--off--disabled--background);
}
.wxc-toggle
  .wxc-toggle__switch--disabled.wxc-toggle__switch--off
  .wxc-toggle__slider {
  background-color: var(--wxc-input--off--disabled--foreground);
}
.wxc-activity-header {
  display: flex;
  flex-direction: column;
  position: relative;
}
.wxc-activity-header .wxc-activity-header__avatar {
  position: absolute;
  margin: 0 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
.wxc-activity-header .wxc-activity-header__author {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: auto;
  display: flex;
  min-width: 18.75rem !important;
  margin-left: 3.5rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  text-align: left;
  color: var(--wxc-secondary-text-color);
  word-wrap: break-word;
}
.wxc-activity-header .wxc-activity-header__timestamp {
  margin-left: 0.5rem;
}
.wxc-activity {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.wxc-activity .wxc-activity__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 15.625rem !important;
  margin-left: 3.5rem;
  color: var(--wxc-text-color);
  word-wrap: break-word;
}
.wxc-activity .wxc-activity__content .wxc-activity__message {
  white-space: pre-wrap;
}
.wxc-greeting {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 37.5rem;
  text-align: center;
  color: var(--wxc-text-color);
  margin: 6.25rem auto 0;
}
.wxc-greeting--desktop {
  margin-top: 11.25rem;
}
.wxc-greeting .wxc-greeting__title {
  font-size: 1.252rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.wxc-greeting .wxc-greeting__description {
  color: var(--wxc-greeting--description--text-color);
}
.wxc-time-ruler {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #dedede;
  width: 100%;
  padding: 0 3rem;
  margin: 0.5rem 0;
}
.wxc-time-ruler:after,
.wxc-time-ruler:before {
  border-top: 0.06rem solid;
  content: "";
  flex: 1 1 auto;
}
.wxc-activity-stream {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.wxc-adaptive-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.wxc-adaptive-card .wxc-adaptive-card__card {
  flex: 1;
}
.wxc-adaptive-card .wxc-adaptive-card__status {
  display: flex;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 1rem;
  color: var(--wxc-adaptive-cards--color--light);
}
.wxc-adaptive-card .wxc-adaptive-card__status--error {
  color: var(--wxc-adaptive-cards--color--attention);
  background-color: var(--wxc-adaptive-cards--background--attention);
  border-radius: 0.5rem;
}
.wxc-adaptive-cards {
  display: flex;
  flex-direction: column;
}
.wxc-adaptive-cards .wxc-adaptive-cards__card {
  flex: 1;
  align-self: stretch;
  margin-bottom: 0.5rem;
}
.wxc-audio-settings {
  display: flex;
  flex-direction: column;
}
.wxc-avatar {
  width: 4rem;
  height: 4rem;
  display: block;
}
.wxc-avatar .wxc-avatar__content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wxc-avatar .wxc-avatar__image-error {
  display: none;
}
.wxc-avatar .wxc-avatar__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  font-size: 0.875rem;
  color: var(--wxc-avatar--text-color);
  text-anchor: middle;
  dominant-baseline: middle;
  fill: currentColor;
  position: absolute;
}
.wxc-avatar .wxc-avatar__image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}
.wxc-avatar .wxc-avatar__status-icon-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 62.5%;
  right: -12.5%;
  top: 56.25%;
  bottom: -6.25%;
  border-radius: 50%;
  background: var(--wxc-icon--background);
}
.wxc-avatar .wxc-avatar__status-icon-container .wxc-avatar__status-icon {
  width: 80%;
  height: 80%;
}
.wxc-avatar .wxc-avatar__bot-badge {
  position: absolute;
  background-color: var(--wxc-avatar--bot-status-color);
  left: -5%;
  top: 72%;
  border-radius: 999rem;
  width: 75%;
  height: 37%;
  text-anchor: middle;
  dominant-baseline: middle;
  fill: var(--wxc-avatar--text-color);
}
.wxc-avatar .wxc-avatar__self-icon {
  z-index: 1;
  width: 70%;
  height: 80%;
  color: var(--wxc-avatar--self-status-color);
}
.wxc-avatar--avatar-self {
  border: 1px solid var(--wxc-avatar--self-status-border);
  border-radius: 50%;
}
.wxc-avatar .wxc-avatar__status-active {
  color: var(--wxc-person-status--active-color);
}
.wxc-avatar .wxc-avatar__status-call,
.wxc-avatar .wxc-avatar__status-meeting {
  color: var(--wxc-person-status--in-a-meeting-color);
}
.wxc-avatar .wxc-avatar__status-dnd,
.wxc-avatar .wxc-avatar__status-presenting {
  color: var(--wxc-person-status--dnd-color);
}
.wxc-avatar .wxc-avatar__status-inactive,
.wxc-avatar .wxc-avatar__status-ooo {
  color: var(--wxc-person-status--away-color);
}
.wxc-avatar .wxc-avatar__placeholder-1 {
  background: var(--wxc-avatar--placeholder-1--background);
}
.wxc-avatar .wxc-avatar__placeholder-2 {
  background: var(--wxc-avatar--placeholder-2--background);
}
.wxc-avatar .wxc-avatar__placeholder-3 {
  background: var(--wxc-avatar--placeholder-3--background);
}
.wxc-avatar .wxc-avatar__placeholder-4 {
  background: var(--wxc-avatar--placeholder-4--background);
}
.wxc-avatar .wxc-avatar__placeholder-5 {
  background: var(--wxc-avatar--placeholder-5--background);
}
.wxc-avatar .wxc-avatar__placeholder-6 {
  background: var(--wxc-avatar--placeholder-6--background);
}
.wxc-avatar .wxc-avatar__placeholder-7 {
  background: var(--wxc-avatar--placeholder-7--background);
}
.wxc-avatar .wxc-avatar__placeholder-8 {
  background: var(--wxc-avatar--placeholder-8--background);
}
.wxc-avatar .wxc-avatar__placeholder-9 {
  background: var(--wxc-avatar--placeholder-9--background);
}
.wxc-avatar .wxc-avatar__placeholder-10 {
  background: var(--wxc-avatar--placeholder-10--background);
}
.wxc-avatar .wxc-avatar__placeholder-11 {
  background: var(--wxc-avatar--placeholder-11--background);
}
.wxc-in-meeting {
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
}
.wxc-in-meeting--remote-sharing .wxc-in-meeting__local-media-in-meeting {
  display: none;
}
.wxc-in-meeting .wxc-in-meeting__media-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
}
.wxc-in-meeting .wxc-in-meeting__share-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.wxc-in-meeting .wxc-in-meeting__remote-media-in-meeting {
  flex: 1;
  min-height: 0;
}
.wxc-in-meeting .wxc-in-meeting__local-media-in-meeting {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  height: 5.625rem;
  width: 10rem;
  min-width: auto;
  min-height: auto;
  background: var(--wxc-secondary-video-background);
}
.wxc-in-meeting--desktop .wxc-in-meeting__local-media-in-meeting,
.wxc-in-meeting--tablet .wxc-in-meeting__local-media-in-meeting {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
}
.wxc-in-meeting--desktop-xl .wxc-in-meeting__local-media-in-meeting {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
}
.wxc-interstitial-meeting {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 17rem;
}
.wxc-interstitial-meeting .wxc-interstitial-meeting__media-container {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.wxc-interstitial-meeting
  .wxc-interstitial-meeting__interstitial-media-container {
  flex: 1;
  min-height: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.wxc-interstitial-meeting .wxc-interstitial-meeting__media {
  flex: 1;
  min-height: 0;
  align-self: stretch;
}
.wxc-interstitial-meeting .wxc-interstitial-meeting__info {
  margin-bottom: 1rem;
}
.wxc-local-media {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 7.5rem;
  min-width: 7.5rem;
  border-radius: 0.5rem;
  background: var(--wxc-video-background);
}
.wxc-local-media .wxc-local-media__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.wxc-media-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--wxc-primary-background);
  color: var(--wxc-text-color);
}
.wxc-media-access .wxc-media-access__logo-container {
  position: absolute;
  top: 0;
  left: 0;
}
.wxc-media-access .wxc-media-access__title {
  margin-top: 0.5rem;
}
.wxc-media-access .wxc-media-access__body {
  color: var(--wxc-secondary-text-color);
  margin-top: 0.5rem;
  margin-bottom: 2.25rem;
  max-width: 25rem;
  text-align: center;
  font-size: 0.875rem;
}
.wxc-media-access .wxc-media-access__body .wxc-media-access__allow {
  font-family: CiscoSansTT Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.wxc-meeting {
  min-height: 25rem;
  min-width: 17rem;
  color: var(--wxc-text-color);
  background: var(--wxc-primary-background);
  border: var(--wxc-meeting-border);
  box-shadow: var(--wxc-box-shadow);
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  transform: translate(0);
}
.wxc-meeting--roster-only .wxc-meeting__inner-meeting {
  display: none;
}
.wxc-meeting--roster-only .wxc-meeting__member-roster {
  flex: 1;
}
.wxc-meeting .wxc-meeting__centered {
  margin: auto;
  text-align: center;
}
.wxc-meeting .wxc-meeting__loading-logo {
  background: var(--wxc-loading-logo--url) no-repeat 50% /
    var(--wxc-loading-logo--size);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wxc-meeting .wxc-meeting__body {
  flex: 1;
  min-height: 0;
  position: relative;
  display: flex;
  align-items: stretch;
}
.wxc-meeting .wxc-meeting__inner-meeting {
  flex: 1;
  min-width: 0;
  margin-bottom: 1rem;
}
.wxc-meeting .wxc-meeting__member-roster {
  width: 23.25rem;
  margin: 0 -1rem 0 0.5rem;
}
.wxc-meeting .wxc-meeting__control-bar {
  margin-bottom: 1.5rem;
}
.wxc-meeting .wxc-meeting__media-state-toast {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  white-space: nowrap;
}
.wxc-meeting .wxc-meeting__settings {
  width: 75%;
  max-width: 60rem;
  min-width: 18.5rem;
  height: 75%;
  max-height: 60rem;
  min-height: 25rem;
}
.wxc-meeting .wxc-meeting__authentication {
  width: 53%;
  max-width: 31.25rem;
  min-width: 18.5rem;
}
.wxc-meeting .wxc-meeting__authentication-guest,
.wxc-meeting .wxc-meeting__authentication-host {
  height: 25rem;
}
.wxc-meeting .wxc-meeting__logo {
  margin: 1rem 0 0.3125rem;
}
.wxc-meeting-guest-authentication {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  color: var(--wxc-text-color);
  line-height: 1.375rem;
  min-width: 0;
}
.wxc-meeting-guest-authentication .wxc-meeting-guest-authentication__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  max-width: 100%;
}
.wxc-meeting-guest-authentication .wxc-meeting-guest-authentication__logo {
  background: var(--wxc-auth-logo--url) no-repeat 50%;
  width: 3.689rem;
  height: 2.625rem;
  margin-bottom: 1.25rem;
}
.wxc-meeting-guest-authentication .wxc-meeting-guest-authentication__title {
  font-family: CiscoSansTT Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__form-content {
  display: flex;
  flex-direction: column;
  width: 17.625rem;
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__form-content
  .wxc-meeting-guest-authentication__input {
  margin-bottom: 1rem;
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__form-content
  .wxc-meeting-guest-authentication__start-button {
  align-items: center;
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__form-content
  .wxc-meeting-guest-authentication__start-button-spinner {
  margin-right: 0.5rem;
}
.wxc-meeting-guest-authentication .wxc-meeting-guest-authentication__host-text {
  margin-top: 1.25rem;
  color: var(--wxc-secondary-text-color);
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__host-text
  .wxc-meeting-guest-authentication__host-hyperlink {
  color: var(--wxc-hyperlink--normal--color);
  text-decoration: underline;
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__host-text
  .wxc-meeting-guest-authentication__host-hyperlink:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__host-text
  .wxc-meeting-guest-authentication__host-hyperlink:hover {
  cursor: pointer;
  color: var(--wxc-hyperlink--hover--color);
}
.wxc-meeting-guest-authentication
  .wxc-meeting-guest-authentication__host-text
  .wxc-meeting-guest-authentication__host-hyperlink:active {
  cursor: pointer;
  color: var(--wxc-hyperlink--active--color);
}
.wxc-meeting-guest-authentication--phone
  .wxc-meeting-guest-authentication__title {
  font-size: 1rem;
  line-height: 1.5rem;
}
.wxc-meeting-host-authentication {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  color: var(--wxc-text-color);
  line-height: 1.375rem;
  min-width: 0;
}
.wxc-meeting-host-authentication .wxc-meeting-host-authentication__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  max-width: 100%;
}
.wxc-meeting-host-authentication .wxc-meeting-host-authentication__logo {
  background: var(--wxc-auth-logo--url) no-repeat 50%;
  width: 3.689rem;
  height: 2.625rem;
  margin-bottom: 1.25rem;
}
.wxc-meeting-host-authentication .wxc-meeting-host-authentication__title {
  font-family: CiscoSansTT Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wxc-meeting-host-authentication
  .wxc-meeting-host-authentication__form-content {
  display: flex;
  flex-direction: column;
  width: 17.625rem;
}
.wxc-meeting-host-authentication
  .wxc-meeting-host-authentication__form-content
  .wxc-meeting-host-authentication__input {
  margin-bottom: 1rem;
}
.wxc-meeting-host-authentication
  .wxc-meeting-host-authentication__form-content
  .wxc-meeting-host-authentication__start-button-spinner {
  margin-right: 0.5rem;
}
.wxc-meeting-host-authentication--phone
  .wxc-meeting-host-authentication__title {
  font-size: 1rem;
  line-height: 1.5rem;
}
.wxc-meeting-control {
  display: inline-block;
  color: var(--wxc-text-color);
}
.wxc-meeting-control--as-item {
  display: flex;
  align-items: baseline;
}
.wxc-meeting-control--as-item .wxc-meeting-control__item-button-icon {
  position: relative;
  top: 0.0625rem;
}
.wxc-meeting-control--as-item .wxc-meeting-control__item-button-text {
  flex: 1;
  margin-left: 0.75rem;
  line-height: 150%;
}
.wxc-meeting-control .wxc-meeting-control__button-text {
  position: relative;
  top: 0.125rem;
  white-space: nowrap;
}
.wxc-meeting-control .wxc-meeting-control__button-text:not(:first-child) {
  margin-left: 0.375rem;
}
.wxc-meeting-control .wxc-meeting-control__control-button,
.wxc-meeting-control .wxc-meeting-control__control-select {
  flex: 1;
}
.wxc-meeting-control .wxc-meeting-control__as-item:hover {
  background: transparent;
}
.wxc-meeting-control-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.wxc-meeting-control-bar .wxc-meeting-control-bar__controls {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.wxc-meeting-control-bar .wxc-meeting-control-bar__controls > * {
  margin: 0.3125rem;
}
.wxc-meeting-control-bar .wxc-meeting-control-bar__controls--control-refs {
  height: 0;
  overflow: hidden;
}
.wxc-meeting-control-bar .wxc-meeting-control-bar__collapsed-controls {
  position: absolute;
  bottom: calc(100% + 0.2rem);
  left: 0;
  right: 0;
  z-index: 100;
}
.wxc-meeting-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--wxc-text-color);
}
.wxc-meeting-info .wxc-meeting-info__title {
  text-align: center;
  margin: 0;
}
.wxc-meeting-info .wxc-meeting-info__time {
  font-size: 0.75rem;
  text-align: center;
}
.wxc-meeting-info--tablet .wxc-meeting-info__title {
  font-size: 1.255rem;
}
.wxc-meeting-info--tablet .wxc-meeting-info__time {
  font-size: 0.875rem;
}
.wxc-meeting-info--desktop .wxc-meeting-info__title {
  font-size: 1.5rem;
}
.wxc-meeting-info--desktop .wxc-meeting-info__time {
  font-size: 1rem;
}
.wxc-member {
  display: flex;
  align-items: center;
  color: var(--wxc-text-color);
}
.wxc-member .wxc-member__avatar {
  height: 2rem;
  width: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.wxc-member .wxc-member__details {
  flex: 1;
  min-width: 0;
  margin-left: 0.75rem;
}
.wxc-member .wxc-member__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.063rem;
}
.wxc-member .wxc-member__name .wxc-member__guest {
  color: var(--wxc-secondary-text-color);
}
.wxc-member .wxc-member__roles {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--wxc-secondary-text-color);
}
.wxc-member .wxc-member__organization {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--wxc-warning-color);
}
.wxc-member .wxc-member__sharing {
  margin-right: 2.5rem;
}
.wxc-member .wxc-member__speaking {
  color: var(--wxc-speaking-color);
}
.wxc-member .wxc-member__muted {
  color: var(--wxc-muted-color);
}
.wxc-member-roster {
  display: flex;
  flex-direction: column;
  color: var(--wxc-text-color);
  background: var(--wxc-secondary-background);
  border-radius: 0.5rem 0 0 0;
  padding: 0.625rem 0 0.625rem 0.75rem;
}
.wxc-member-roster .wxc-member-roster__section-title {
  color: var(--wxc-secondary-text-color);
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin: 0;
  letter-spacing: normal;
  padding: 0.438rem 0 0.188rem;
}
.wxc-member-roster .wxc-member-roster__external-user-warning {
  color: var(--wxc-warning-color);
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin-top: 0.75rem;
}
.wxc-member-roster
  .wxc-member-roster__external-user-warning
  .wxc-member-roster__external-user-icon {
  margin-right: 0.578rem;
}
.wxc-member-roster
  .wxc-member-roster__external-user-warning
  .wxc-member-roster__external-user-message {
  flex: 1;
}
.wxc-member-roster .wxc-member-roster__header {
  display: flex;
  align-items: center;
  padding-right: 0.375rem;
}
.wxc-member-roster .wxc-member-roster__header .wxc-member-roster__title {
  font-family: CiscoSansTT Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
  flex: 1;
}
.wxc-member-roster .wxc-member-roster__members {
  overflow-y: auto;
  flex: 1;
  padding-right: 1.25rem;
  scrollbar-width: thin;
}
.wxc-member-roster ::-webkit-scrollbar {
  width: 1rem;
}
.wxc-member-roster ::-webkit-scrollbar-thumb {
  background: var(--wxc-scrollbar--thumb);
  border-radius: 1rem;
}
.wxc-messaging__loading-logo {
  background: var(--wxc-loading-logo--url) no-repeat 50% /
    var(--wxc-loading-logo--size);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wxc-no-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  color: var(--wxc-text-color);
}
.wxc-no-media .wxc-no-media__icon-media-issue {
  fill: transparent;
}
.wxc-no-media .wxc-no-media__title {
  color: var(--wxc-error-color);
}
.wxc-no-media .wxc-no-media__indications {
  margin-top: 1.875rem;
  margin-bottom: 0;
}
.wxc-no-media .wxc-no-media__indications .wxc-no-media__accent {
  font-family: CiscoSansTT Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.wxc-remote-media {
  background: var(--wxc-video-background);
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wxc-remote-media .wxc-remote-media__media-error {
  font-family: CiscoSansTT Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--wxc-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wxc-remote-media .wxc-remote-media__error-icon {
  margin-bottom: 1.234rem;
}
.wxc-remote-media .wxc-remote-media__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.wxc-remote-media .wxc-remote-media__connecting-badge {
  z-index: 5;
  white-space: nowrap;
}
.wxc-remote-media .wxc-remote-media__connecting-spinner {
  margin-right: 0.6875rem;
}
.wxc-remote-media--remote-video-n-share .wxc-remote-media__remote-video {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  height: 5.625rem;
  width: 10rem;
}
.wxc-remote-media--remote-video-n-share .wxc-remote-media__remote-share {
  flex-grow: 1;
}
.wxc-remote-media--desktop.wxc-remote-media--remote-video-n-share
  .wxc-remote-media__remote-video,
.wxc-remote-media--tablet.wxc-remote-media--remote-video-n-share
  .wxc-remote-media__remote-video {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
}
.wxc-remote-media--desktop-xl.wxc-remote-media--remote-video-n-share
  .wxc-remote-media__remote-video {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
}
.wxc-tabs .wxc-tabs__list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 1.5rem 1.375rem;
}
.wxc-tabs .wxc-tabs__list .wxc-tabs__tab button {
  background: var(--wxc-tab--normal--background);
  color: var(--wxc-tab--normal--color);
  border: var(--wxc-tab--normal--border);
  cursor: pointer;
  font-family: CiscoSansTT Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0.1875rem 1rem;
  border-radius: 0.875rem;
}
.wxc-tabs .wxc-tabs__list .wxc-tabs__tab button:focus-visible {
  box-shadow: var(--wxc-focus-box-shadow);
  outline: none;
}
.wxc-tabs .wxc-tabs__list .wxc-tabs__tab button:hover {
  color: var(--wxc-tab--hover--color);
  background: var(--wxc-tab--hover--background);
  border: var(--wxc-tab--hover--border);
}
.wxc-tabs .wxc-tabs__list .wxc-tabs__tab button:active {
  color: var(--wxc-tab--active--color);
  background: var(--wxc-tab--active--background);
  border: var(--wxc-tab--active--border);
}
.wxc-tabs .wxc-tabs__list .wxc-tabs__tab--active > button {
  color: var(--wxc-tab--active--color);
  background: var(--wxc-tab--active--background);
}
.wxc-tabs .wxc-tabs__list .wxc-tabs__tab:not(:last-child) {
  margin-right: 0.5rem;
}
.wxc-tabs .wxc-tabs__content {
  padding: 0 1.375rem;
}
.wxc-video-settings {
  display: flex;
  flex-direction: column;
}
.wxc-video-settings .wxc-video-settings__media {
  position: relative;
  margin-top: 1.5rem;
}
.wxc-video-settings .wxc-video-settings__no-media {
  margin: 2.5rem 1.875rem 1.125rem;
}
.wxc-waiting-for-host {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  border-radius: 0.5rem;
}
.wxc-waiting-for-host .wxc-waiting-for-host__info {
  margin-bottom: 1rem;
}
.wxc-waiting-for-host .wxc-waiting-for-host__content {
  flex: 1;
  min-height: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--wxc-video-background);
  color: var(--wxc-text-color);
  border-radius: 0.5rem;
}
.wxc-waiting-for-host .wxc-waiting-for-host__icon {
  margin-bottom: 0.5rem;
}
.wxc-waiting-for-host .wxc-waiting-for-host__text {
  width: 15rem;
  height: 2.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.3rem;
  margin-bottom: 1.75rem;
}
.wxc-waiting-for-host .wxc-waiting-for-host__local-media {
  box-shadow: 0.25rem 0.25rem 1rem -0.125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  height: 5.625rem;
  width: 10rem;
  min-width: auto;
  min-height: auto;
  background: var(--wxc-secondary-video-background);
}
.wxc-search-people {
  min-width: 300px;
  max-width: 450px;
  width: 450px;
  color: var(--wxc-text-color);
  margin-top: 20px;
}
.wxc-search-people .wxc-search-people__people-list-box {
  background: var(--wxc-options-list--normal--background);
  width: auto;
  max-height: 105px;
  overflow-y: auto;
  border: 0.0625rem solid #ccc;
  border-radius: 0.5rem;
  margin-top: 8px;
  stroke-width: 1px;
  transition: box-shadow 0.15s ease-out;
  text-align: left;
}
.wxc-search-people
  .wxc-search-people__people-list-box
  .wxc-search-people__people-added {
  background: var(--wxc-primary-background);
  box-shadow: var(--wxc-box-shadow);
  display: inline-block;
  font-size: 12px;
  border-radius: 30px;
  height: 24px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.3rem;
}
.wxc-search-people
  .wxc-search-people__people-list-box
  .wxc-search-people__people-added
  .wxc-search-people__people-added-content {
  padding-right: 5px;
}
.wxc-search-people
  .wxc-search-people__people-list-box
  .wxc-search-people__input {
  border: none;
}
.wxc-search-people .wxc-search-people__suggestions {
  height: 200px;
  width: auto;
  overflow-y: auto;
}
.wxc-search-people
  .wxc-search-people__suggestions
  .wxc-search-people__suggestions-list {
  display: flex;
}
.wxc-search-people
  .wxc-search-people__suggestions
  .wxc-search-people__suggestions-list
  .wxc-search-people__avatar {
  width: 2.5rem;
  height: auto;
  border-radius: 20px;
}
.wxc-search-people
  .wxc-search-people__suggestions
  .wxc-search-people__suggestions-list
  .wxc-search-people__data {
  padding-left: 10px;
  text-align: left;
}
.wxc-search-people
  .wxc-search-people__suggestions
  .wxc-search-people__suggestions-list
  .wxc-search-people__list-selected {
  color: var(--wxc-options-list--checked-icon--color);
  flex: 1;
  text-align: end;
}
.wxc-search-people
  .wxc-search-people__suggestions
  .wxc-search-people__list-heading {
  padding: 5px;
  border-bottom: 1px solid grey;
  background: var(--wxc-options-list--normal--background);
  text-align: left;
}
.wxc-search-people
  .wxc-search-people__suggestions
  .wxc-search-people__search-error {
  font-size: 12px;
  padding: 10px;
  color: grey;
  background: var(--wxc-options-list--normal--background);
  text-align: center;
}
.wxc-search-people .wxc-input-field__input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.wxc-search-people .wxc-options-list {
  border-radius: 0;
}
@media only screen and (max-width: 450px) {
  .wxc-search-people {
    width: 250px;
    min-width: 0;
    color: var(--wxc-text-color);
    margin-top: 20px;
  }
}
.wxc-create-space {
  min-width: 300px;
  width: 450px;
}
.wxc-create-space .wxc-create-space__buttons {
  display: flex;
  flex: 1 1 auto;
  gap: 20px;
  margin-top: 15px;
}
.wxc-create-space .wxc-create-space__error {
  display: flex;
  color: var(--wxc-error-color);
}
.wxc-create-space .wxc-create-space__success {
  display: flex;
  color: var(--wxc-speaking-color);
}
.wxc-create-space .wxc-input-field__input {
  outline: none !important;
  box-shadow: none !important;
}
.wxc-create-space .wxc-options-list {
  border-radius: 0;
}
@media only screen and (max-width: 450px) {
  .wxc-button {
    font-size: 0.7rem;
  }
  .wxc-create-space {
    width: 250px;
    min-width: 0;
  }
}
.webex-meetings-widget {
  max-width: 100%;
  min-width: 18.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: var(--wxc-text-color);
}
.webex-meetings-widget__content {
  flex: 1;
  min-height: 0;
}
