$shadow-offset: 0.25rem;
$shadow-blur: 1rem;
$shadow-spread: -0.125rem;
$shadow-color: rgba(0, 0, 0, 0.2);

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: auto;
}

@mixin body-font {
  font-family: $brand-font-regular;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
}

@mixin media-shadow {
  box-shadow: $shadow-offset $shadow-offset $shadow-blur $shadow-spread $shadow-color;
  border-radius: 0.5rem;
}

@mixin media-preview-mobile {
  @include media-shadow();

  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

@mixin media-preview-tablet {
  @include media-shadow();

  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
}

@mixin media-preview-desktop-xl {
  @include media-shadow();

  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
}

@mixin focusable {
  &:focus-visible {
    box-shadow: var(--wxc-focus-box-shadow);
    outline: none;
  }
}
