$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-meeting-control-bar;

.#{$C} {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .#{$C}__controls {
    white-space: nowrap;
    display: flex;
    align-items: center;

    > * {
      margin: 0.3125rem;
    }

    &--control-refs {
      height: 0;
      overflow: hidden;
    }
  }

  .#{$C}__collapsed-controls {
    position: absolute;
    bottom: calc(100% + 0.2rem);
    left: 0;
    right: 0;
    z-index: 100;
  }
}
