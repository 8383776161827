.#{$WEBEX_COMPONENTS_CLASS_PREFIX}-theme-dark, .md-theme-darkWebex, :root {
  --wxc-browser-color-scheme: dark;

  --wxc-loading-logo--url: url(../themes/dark/loading.gif);
  --wxc-loading-logo--size: 11.6875rem;
  --wxc-auth-logo--url: url(../themes/dark/webex-logo.svg);

  --wxc-text-color: #{$wxc-white-alpha-95};
  --wxc-secondary-text-color: #{$wxc-white-alpha-70};
  --wxc-warning-color: #{$wxc-yellow-40};
  --wxc-error-color: #{$wxc-red-40};
  --wxc-muted-color: #{$wxc-red-50};
  --wxc-speaking-color: #{$wxc-green-40};

  --wxc-primary-background: #{$wxc-gradation-webex-dark-primary};
  --wxc-secondary-background: #{$wxc-gradation-webex-dark-secondary};
  --wxc-video-background: #{$wxc-white-alpha-05};
  --wxc-secondary-video-background: #{$wxc-gray-100};

  --wxc-meeting-border: 1px solid #{$wxc-white-alpha-30};
  --wxc-box-shadow: none;
  --wxc-focus-box-shadow: 0 0 0 0.25rem #{$wxc-blue-40-alpha-30}, 0 0 0 0.125rem #{$wxc-blue-40};

  --wxc-person-status--active-color: #{$wxc-green-40};
  --wxc-person-status--dnd-color: #{$wxc-red-50};
  --wxc-person-status--in-a-meeting-color: #{$wxc-yellow-30};
  --wxc-person-status--away-color: #{$wxc-gray-40};

  --wxc-badge-background: #{$wxc-gray-100};
  --wxc-badge-border: 0.06rem solid #{$wxc-white-alpha-30};
  --wxc-badge-text-color: #{$wxc-white-alpha-95};
  --wxc-badge-box-shadow: 0 .5rem 1rem #{$wxc-black-alpha-16}, 0 0 .06rem #{$wxc-black-alpha-14};

  --wxc-avatar--placeholder-1--background: #{$wxc-white-alpha-50};
  --wxc-avatar--placeholder-2--background: #{$wxc-cobalt-70};
  --wxc-avatar--placeholder-3--background: #{$wxc-cyan-70};
  --wxc-avatar--placeholder-4--background: #{$wxc-gold-70};
  --wxc-avatar--placeholder-5--background: #{$wxc-lime-70};
  --wxc-avatar--placeholder-6--background: #{$wxc-mint-70};
  --wxc-avatar--placeholder-7--background: #{$wxc-orange-70};
  --wxc-avatar--placeholder-8--background: #{$wxc-pink-70};
  --wxc-avatar--placeholder-9--background: #{$wxc-slate-70};
  --wxc-avatar--placeholder-10--background: #{$wxc-violet-70};
  --wxc-avatar--placeholder-11--background: #{$wxc-purple-70};
  --wxc-avatar--bot-status-color: #{$wxc-blue-60};
  --wxc-avatar--self-status-color: #{$wxc-cobalt-50};
  --wxc-avatar--self-status-border: #{$wxc-white-alpha-100};
  --wxc-avatar--text-color: #{$wxc-white-alpha-100};

  --wxc-banner--top--background: #{$wxc-gradation-dark-transparent};
  --wxc-banner--bottom--background: #{$wxc-black-alpha-70};

  --wxc-icon--background: #{$wxc-black-alpha-100};
  --wxc-icon--color: #{$wxc-white-alpha-100};
  --wxc-icon--accent-color: #{$wxc-green-40};
  --wxc-icon--muted-color: #{$wxc-red-50};

  --wxc-icon--error--gradient-start-color: #{$wxc-pink-50};
  --wxc-icon--error--gradient-stop-color: #{$wxc-red-60};

  --wxc-modal--background: #{$wxc-gray-100};
  --wxc-modal--border: 1px solid #{$wxc-black-alpha-11};
  --wxc-modal--box-shadow: 0 1.5rem 3rem #{$wxc-black-alpha-20}, 0 0 0.0625rem #{$wxc-black-alpha-12};

  --wxc-button--cancel--normal--background: #{$wxc-red-60};
  --wxc-button--cancel--normal--color: #{$wxc-white-alpha-95};
  --wxc-button--cancel--normal--border: none;

  --wxc-button--cancel--active--background: #{$wxc-red-80};
  --wxc-button--cancel--active--color: #{$wxc-white-alpha-95};
  --wxc-button--cancel--active--border: none;

  --wxc-button--cancel--hover--background: #{$wxc-red-70};
  --wxc-button--cancel--hover--color: #{$wxc-white-alpha-95};
  --wxc-button--cancel--hover--border: none;

  --wxc-button--cancel--disabled--background: #{$wxc-white-alpha-20};
  --wxc-button--cancel--disabled--color: #{$wxc-white-alpha-40};
  --wxc-button--cancel--disabled--border: none;

  --wxc-button--default--normal--background: transparent;
  --wxc-button--default--normal--color: #{$wxc-white-alpha-95};
  --wxc-button--default--normal--border: 1px solid #{$wxc-white-alpha-30};

  --wxc-button--default--active--background: #{$wxc-white-alpha-20};
  --wxc-button--default--active--color: #{$wxc-white-alpha-95};
  --wxc-button--default--active--border: 1px solid #{$wxc-white-alpha-30};

  --wxc-button--default--hover--background: #{$wxc-white-alpha-07};
  --wxc-button--default--hover--color: #{$wxc-white-alpha-95};
  --wxc-button--default--hover--border: 1px solid #{$wxc-white-alpha-30};

  --wxc-button--default--disabled--background: transparent;
  --wxc-button--default--disabled--color: #{$wxc-white-alpha-40};
  --wxc-button--default--disabled--border: 1px solid #{$wxc-white-alpha-20};

  --wxc-button--ghost--normal--background: transparent;
  --wxc-button--ghost--normal--color: #{$wxc-white-alpha-100};
  --wxc-button--ghost--normal--border: none;

  --wxc-button--ghost--hover--background: #{$wxc-white-alpha-07};
  --wxc-button--ghost--hover--color: #{$wxc-white-alpha-100};
  --wxc-button--ghost--hover--border: none;

  --wxc-button--ghost--active--background: #{$wxc-white-alpha-20};
  --wxc-button--ghost--active--color: #{$wxc-white-alpha-100};
  --wxc-button--ghost--active--border: none;

  --wxc-button--ghost--disabled--background: #{$wxc-white-alpha-20};
  --wxc-button--ghost--disabled--color: #{$wxc-white-alpha-40};
  --wxc-button--ghost--disabled--border: none;

  --wxc-button--join--normal--background: #{$wxc-green-60};
  --wxc-button--join--normal--color: #{$wxc-white-alpha-95};
  --wxc-button--join--normal--border: none;

  --wxc-button--join--active--background: #{$wxc-green-80};
  --wxc-button--join--active--color: #{$wxc-white-alpha-95};
  --wxc-button--join--active--border: none;

  --wxc-button--join--hover--background: #{$wxc-green-70};
  --wxc-button--join--hover--color: #{$wxc-white-alpha-95};
  --wxc-button--join--hover--border: none;

  --wxc-button--join--disabled--background: #{$wxc-white-alpha-20};
  --wxc-button--join--disabled--color: #{$wxc-white-alpha-40};
  --wxc-button--join--disabled--border: none;

  --wxc-button--primary--normal--background: #{$wxc-white-alpha-95};
  --wxc-button--primary--normal--color: #{$wxc-black-alpha-95};
  --wxc-button--primary--normal--border: none;

  --wxc-button--primary--active--background: #{$wxc-white-alpha-70};
  --wxc-button--primary--active--color: #{$wxc-black-alpha-95};
  --wxc-button--primary--active--border: none;

  --wxc-button--primary--hover--background: #{$wxc-white-alpha-80};
  --wxc-button--primary--hover--color: #{$wxc-black-alpha-95};
  --wxc-button--primary--hover--border: none;

  --wxc-button--primary--disabled--background: #{$wxc-white-alpha-20};
  --wxc-button--primary--disabled--color: #{$wxc-white-alpha-40};
  --wxc-button--primary--disabled--border: none;

  --wxc-spinner--circle--color: #{$wxc-white-alpha-11};
  --wxc-spinner--moving--color: #{$wxc-white-alpha-95};

  --wxc-tab--normal--background: transparent;
  --wxc-tab--normal--color: #{$wxc-white-alpha-70};
  --wxc-tab--normal--border: none;

  --wxc-tab--active--background: #{$wxc-white-alpha-20};
  --wxc-tab--active--color: #{$wxc-white-alpha-95};
  --wxc-tab--active--border: none;

  --wxc-tab--hover--background: #{$wxc-white-alpha-07};
  --wxc-tab--hover--color: #{$wxc-white-alpha-95};
  --wxc-tab--hover--border: none;

  --wxc-dropdown--normal--background: #{$wxc-black-alpha-100};
  --wxc-dropdown--normal--color: #{$wxc-white-alpha-70};
  --wxc-dropdown--normal--box-shadow: none;
  --wxc-dropdown--normal--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-dropdown--normal--outline: none;

  --wxc-dropdown--hover--background: #{$wxc-white-alpha-07};
  --wxc-dropdown--hover--color: #{$wxc-white-alpha-70};
  --wxc-dropdown--hover--box-shadow: none;
  --wxc-dropdown--hover--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-dropdown--hover--outline: none;

  --wxc-dropdown--active--background: #{$wxc-white-alpha-11};
  --wxc-dropdown--active--color: #{$wxc-white-alpha-95};
  --wxc-dropdown--active--box-shadow: 0 0 0 0.25rem #{$wxc-blue-40-alpha-30}, 0 0 0 0.125rem #{$wxc-blue-40};
  --wxc-dropdown--active--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-dropdown--active--outline: none;

  --wxc-dropdown--disabled--background: #{$wxc-white-alpha-11};
  --wxc-dropdown--disabled--color: #{$wxc-white-alpha-40};
  --wxc-dropdown--disabled--box-shadow: 0 0 0 2px #{$wxc-blue-40}, 0px 0px 0px 4px #{$wxc-blue-60};
  --wxc-dropdown--disabled--border: 1px solid #{$wxc-white-alpha-11};
  --wxc-dropdown--disabled--outline: none;

  --wxc-dropdown--focus--background: #{$wxc-black-alpha-100};
  --wxc-dropdown--focus--color: #{$wxc-white-alpha-95};
  --wxc-dropdown--focus--box-shadow: 0 0 0 0.25rem #{$wxc-blue-40-alpha-30}, 0 0 0 0.125rem #{$wxc-blue-40};
  --wxc-dropdown--focus--border: 1px solid #{$wxc-white-alpha-90};
  --wxc-dropdown--focus--outline: none;

  --wxc-input-field--normal--background: #{$wxc-black-alpha-00};
  --wxc-input-field--normal--color: #{$wxc-white-alpha-95};
  --wxc-input-field--normal--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-input-field--normal--box-shadow: none;
  --wxc-input-field--normal--outline: none;

  --wxc-input-field--hover--background: #{$wxc-white-alpha-07};
  --wxc-input-field--hover--color: #{$wxc-white-alpha-95};
  --wxc-input-field--hover--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-input-field--hover--box-shadow: none;
  --wxc-input-field--hover--outline: none;

  --wxc-input-field--active--background: #{$wxc-white-alpha-11};
  --wxc-input-field--active--color: #{$wxc-white-alpha-95};
  --wxc-input-field--active--border: 1px solid #{$wxc-white-alpha-90};
  --wxc-input-field--active--box-shadow: 0 0 0 0.25rem #{$wxc-blue-40-alpha-30}, 0 0 0 0.125rem #{$wxc-blue-40};
  --wxc-input-field--active--outline: none;

  --wxc-input-field--focus--background: #{$wxc-white-alpha-11};
  --wxc-input-field--focus--color: #{$wxc-white-alpha-95};
  --wxc-input-field--focus--border: 1px solid #{$wxc-white-alpha-90};
  --wxc-input-field--focus--box-shadow: 0 0 0 0.25rem #{$wxc-blue-40-alpha-30}, 0 0 0 0.125rem #{$wxc-blue-40};
  --wxc-input-field--focus--outline: none;

  --wxc-input-field--disabled--background: #{$wxc-white-alpha-11};
  --wxc-input-field--disabled--color: #{$wxc-white-alpha-11};
  --wxc-input-field--disabled--border: 1px solid transparent;
  --wxc-input-field--disabled--box-shadow: none;
  --wxc-input-field--disabled--outline: none;

  --wxc-input-field--error-static--background: #{$wxc-red-90};
  --wxc-input-field--error-static--color: #{$wxc-white-alpha-95};
  --wxc-input-field--error-static--border: 1px solid #{$wxc-red-40};
  --wxc-input-field--error-static--box-shadow: none;
  --wxc-input-field--error-static--outline: none;

  --wxc-input-field--error-hover--background: #{$wxc-white-alpha-07};
  --wxc-input-field--error-hover--color: #{$wxc-white-alpha-95};
  --wxc-input-field--error-hover--border: 1px solid #{$wxc-red-40};
  --wxc-input-field--error-hover--box-shadow: none;
  --wxc-input-field--error-hover--outline: none;

  --wxc-input-field--error-focus--background: #{$wxc-white-alpha-11};
  --wxc-input-field--error-focus--color: #{$wxc-white-alpha-95};
  --wxc-input-field--error-focus--border: 1px solid #{$wxc-red-40};
  --wxc-input-field--error-focus--box-shadow: none;
  --wxc-input-field--error-focus--outline: none;

  --wxc-input-icon-color: #{$wxc-white-alpha-70};

  --wxc-input--label--color: #{$wxc-white-alpha-95};

  --wxc-options-list--background: #{$wxc-gray-95};

  --wxc-options-list--item--focus--box-shadow: 0 0 0 2px #{$wxc-blue-40} inset;
  --wxc-options-list--item--focus--outline: 2px solid #{$wxc-blue-40-alpha-30};

  --wxc-options-list--item--hover--background: #{$wxc-white-alpha-07};
  --wxc-options-list--item--hover--color: #{$wxc-white-alpha-95};
  --wxc-options-list--item--hover--border: none;

  --wxc-options-list--item--active--background: #{$wxc-white-alpha-11};
  --wxc-options-list--item--active--color: #{$wxc-white-alpha-95};
  --wxc-options-list--item--active--border: none;

  --wxc-options-list--item--selected--background: #{$wxc-gray-95};
  --wxc-options-list--item--selected--color: #{$wxc-white-alpha-95};
  --wxc-options-list--item--selected--border: none;

  --wxc-options-list--checked-icon--color: #{$wxc-blue-40};

  --wxc-popup--box-shadow: none;
  --wxc-popup--border: none;

  --wxc-scrollbar--thumb: #{$wxc-white-alpha-11};
  --wxc-scrollbar--background: #{$wxc-white-alpha-20};

  --wxc-hyperlink--normal--color: #{$wxc-blue-40};
  --wxc-hyperlink--hover--color: #{$wxc-blue-20};
  --wxc-hyperlink--active--color:#{$wxc-blue-20};

  --wxc-tooltip-background: #{$wxc-black-alpha-100};
  --wxc-tooltip-border: 1px solid #{$wxc-white-alpha-20};
  --wxc-tooltip-border-color: #{$wxc-white-alpha-20};

  --wxc-adaptive-cards--card--border: 1px solid #{$wxc-white-alpha-20};
  --wxc-adaptive-cards--card--border-radius: 8px;

  --wxc-adaptive-cards--background--default: #{$wxc-gray-95};
  --wxc-adaptive-cards--background--emphasis: #{$wxc-gray-80};
  --wxc-adaptive-cards--background--accent: #{$wxc-blue-80};
  --wxc-adaptive-cards--background--good: #{$wxc-green-80};
  --wxc-adaptive-cards--background--attention: #{$wxc-red-80};
  --wxc-adaptive-cards--background--warning: #{$wxc-yellow-80};

  --wxc-adaptive-cards--color--default: #{$wxc-white-alpha-95};
  --wxc-adaptive-cards--color--dark: #{$wxc-white-alpha-95};
  --wxc-adaptive-cards--color--light: #{$wxc-white-alpha-70};
  --wxc-adaptive-cards--color--accent: #{$wxc-blue-40};
  --wxc-adaptive-cards--color--good: #{$wxc-green-40};
  --wxc-adaptive-cards--color--warning: #{$wxc-yellow-40};
  --wxc-adaptive-cards--color--attention: #{$wxc-red-40};

  --wxc-adaptive-cards--subtle-color--default: #{$wxc-white-alpha-70};
  --wxc-adaptive-cards--subtle-color--dark: #{$wxc-white-alpha-70};
  --wxc-adaptive-cards--subtle-color--light: #{$wxc-white-alpha-60};
  --wxc-adaptive-cards--subtle-color--accent: #{$wxc-blue-30};
  --wxc-adaptive-cards--subtle-color--good: #{$wxc-green-30};
  --wxc-adaptive-cards--subtle-color--warning: #{$wxc-yellow-30};
  --wxc-adaptive-cards--subtle-color--attention: #{$wxc-red-30};

  --wxc-adaptive-cards--highlight-color: #{$wxc-gray-40};
  --wxc-adaptive-cards--separator-color: #{$wxc-gray-50};

  --wxc-input--title--normal: #{$wxc-white-alpha-95};
  --wxc-input--title--disabled: #{$wxc-white-alpha-40};

  --wxc-input--on--normal--border: none;
  --wxc-input--on--normal--background: #{$wxc-blue-40};
  --wxc-input--on--normal--foreground: #{$wxc-black-alpha-95};

  --wxc-input--on--hover--border: none;
  --wxc-input--on--hover--background: #{$wxc-blue-50};
  --wxc-input--on--hover--foreground: #{$wxc-black-alpha-95};

  --wxc-input--on--active--border: none;
  --wxc-input--on--active--background: #{$wxc-blue-60};
  --wxc-input--on--active--foreground: #{$wxc-black-alpha-95};

  --wxc-input--on--disabled--border: none;
  --wxc-input--on--disabled--background: #{$wxc-blue-80};
  --wxc-input--on--disabled--foreground: #{$wxc-black-alpha-50};

  --wxc-input--off--normal--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-input--off--normal--background:  #{$wxc-white-alpha-20};
  --wxc-input--off--normal--foreground: #{$wxc-white-alpha-95};

  --wxc-input--off--hover--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-input--off--hover--background: #{$wxc-white-alpha-30};
  --wxc-input--off--hover--foreground: #{$wxc-white-alpha-95};

  --wxc-input--off--active--border: 1px solid #{$wxc-white-alpha-50};
  --wxc-input--off--active--background: #{$wxc-white-alpha-40};
  --wxc-input--off--active--foreground: #{$wxc-white-alpha-95};

  --wxc-input--off--disabled--border: none;
  --wxc-input--off--disabled--background: #{$wxc-white-alpha-11};
  --wxc-input--off--disabled--foreground: #{$wxc-white-alpha-40};

  --wxc-date-input--calendar-picker-indicator--filter: invert(5%);

  --wxc-adaptive-cards--action--hover--border: inherit;
  --wxc-adaptive-cards--action--hover--background: #{$wxc-white-alpha-07};
  --wxc-adaptive-cards--action--hover--foreground: inherit;


  --wxc-adaptive-cards--action--active--border: inherit;
  --wxc-adaptive-cards--action--active--background: #{$wxc-white-alpha-11};
  --wxc-adaptive-cards--action--active--foreground: inherit;

  --wxc-greeting--description--text-color: #{$wxc-white-alpha-70};
}
