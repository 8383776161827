$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-input-field;

.#{$C} {
  display: flex;
  flex-direction: column;
  position: relative;

  .#{$C}__form-control {
    position: relative;
    display: flex;
  }

  .#{$C}__input {
    @include body-font;
    background: var(--wxc-input-field--normal--background);
    border: var(--wxc-input-field--normal--border);
    color: var(--wxc-input-field--normal--color);
    border-radius: 0.5rem;
    padding: 0.313rem 0.75rem 0.063rem 0.75rem;
    width: 100%;
    height: 2rem; // prevent height collapse on iOS
    -webkit-appearance: none; // to be able to display box-shadow on iOS mobile

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:hover::-webkit-inner-spin-button,
      &:hover::-webkit-outer-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
    }

    @mixin disabled {
      background: var(--wxc-input-field--disabled--background);
      border: var(--wxc-input-field--disabled--border);
      color: var(--wxc-input-field--disabled--color);
      box-shadow: var(--wxc-input-field--disabled--box-shadow);
      outline: var(--wxc-input-field--disabled--outline);
    }

    @mixin static {
      background: var(--wxc-input-field--normal--background);
      border: var(--wxc-input-field--normal--border);
      color: var(--wxc-input-field--normal--color);
      box-shadow: (--wxc-input-field--normal--box-shadow);
      outline: var(--wxc-input-field--normal--outline);
    }

    @mixin hover {
      background: var(--wxc-input-field--hover--background);
      border: var(--wxc-input-field--hover--border);
      color: var(--wxc-input-field--hover--color);
      box-shadow: var(--wxc-input-field--hover--box-shadow);
      outline: var(--wxc-input-field--hover--outline);
    }

    @mixin focus {
      background: var(--wxc-input-field--focus--background);
      border: var(--wxc-input-field--focus--border);
      color: var(--wxc-input-field--focus--color);
      box-shadow: var(--wxc-input-field--focus--box-shadow);
      outline: var(--wxc-input-field--focus--outline);
    }

    @mixin error-static {
      background: var(--wxc-input-field--error-static--background);
      border: var(--wxc-input-field--error-static--border);
      color: var(--wxc-input-field--error-static--color);
      box-shadow: var(--wxc-input-field--error-static--box-shadow);
      outline: var(--wxc-input-field--error-static--outline);
    }

    @mixin error-hover {
      background: var(--wxc-input-field--error-hover--background);
      border: var(--wxc-input-field--error-hover--border);
      color: var(--wxc-input-field--error-hover--color);
      box-shadow: var(--wxc-input-field--error-hover--box-shadow);
      outline: var(--wxc-input-field--error-hover--outline);
    }

    @mixin error-focus {
      background: var(--wxc-input-field--error-focus--background);
      border: var(--wxc-input-field--error-focus--border);
      color: var(--wxc-input-field--error-focus--color);
      box-shadow: var(--wxc-input-field--error-focus--box-shadow);
      outline: var(--wxc-input-field--error-focus--outline);
    }

    &:disabled {
      @include disabled;
    }

    &:not(:disabled) {
      &:not(&.#{$C}__error) {
        &:not(:focus) {
          &:not(:hover) {
            @include static;
          }

          &:hover {
            @include hover;
          }
        }

        &:focus {
          @include focus;
        }
      }

      &.#{$C}__error {
        &:not(:focus) {
          &:not(:hover) {
            @include error-static;
          }

          &:hover {
            @include error-hover;
          }
        }

        &:focus {
          @include error-focus;
        }
      }
    }
  }

  .#{$C}__right-icon {
    position: absolute;
    color: var(--wxc-input-icon-color);
    top: 50%;
    right: 2px;
    transform: translateY(-50%);

    &:not(:last-child) {
      right: 2rem;
    }
  }

  &--has-right-icon {
    .#{$C}__input{
      padding-right: 1.875rem;
    }
  }

  &--has-right-controls {
    .#{$C}__input{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
