.#{$WEBEX_COMPONENTS_CLASS_PREFIX}-ac-action {
  &__icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.375rem;
  }

  &__right-icon {
    margin-left: 0.375rem;
  }

  .wxc-ac-container--vertical > &:not(:first-child) { margin-top: 0.5rem; }
  .wxc-ac-container--horizontal > &:not(:first-child) { margin-left: 0.5rem; }

}
