.wxc {
  @include body-font;
  color-scheme: var(--wxc-browser-color-scheme);
}

.wxc, .wxc * {
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--wxc-scrollbar--background);
    border-radius: 0.125rem;
  }
}
