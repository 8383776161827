$C: #{$WEBEX_COMPONENTS_CLASS_PREFIX}-textbox;

.#{$C} {
  &__control {
    @include body-font;
    width: 100%;
    height: 100%;
    background: var(--wxc-input-field--normal--background);
    color: var(--wxc-input-field--normal--color);
    border: var(--wxc-input-field--normal--border);
    box-shadow: var(--wxc-input-field--normal--box-shadow);
    outline: var(--wxc-input-field--normal--outline);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    text-align: left;
    resize: none;
    -webkit-appearance: none; // to be able to display box-shadow on iOS mobile

    &:hover {
      background: var(--wxc-input-field--hover--background);
      color: var(--wxc-input-field--hover--color);
      border: var(--wxc-input-field--hover--border);
      box-shadow: var(--wxc-input-field--hover--box-shadow);
      outline: var(--wxc-input-field--hover--outline);
    }

    &:active {
      background: var(--wxc-input-field--active--background);
      color: var(--wxc-input-field--active--color);
      border: var(--wxc-input-field--active--border);
      box-shadow: var(--wxc-input-field--active--box-shadow);
      outline: var(--wxc-input-field--active--outline);
    }

    &:focus {
      background: var(--wxc-input-field--focus--background);
      color: var(--wxc-input-field--focus--color);
      border: var(--wxc-input-field--focus--border);
      box-shadow: var(--wxc-input-field--focus--box-shadow);
      outline: var(--wxc-input-field--focus--outline);
    }

    &:disabled {
      background: var(--wxc-input-field--disabled--background);
      border: var(--wxc-input-field--disabled--border);
      color: var(--wxc-input-field--disabled--color);
      box-shadow: var(--wxc-input-field--disabled--box-shadow);
      outline: var(--wxc-input-field--disabled--outline);
    }
  }
}
