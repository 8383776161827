// Note, this string must match the one from src/constants.js
$WEBEX_COMPONENTS_CLASS_PREFIX: 'wxc';

$WEBEX_MEETING_MIN_HEIGHT: 25rem;
$WEBEX_MEETING_MIN_WIDTH: 17rem; // Standard small phone width is 20rem, leaving some padding
$brand-font-folder: '../assets/fonts';

$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, Cambria, 'Times New Roman', Times, serif;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;

$brand-font-bold: 'CiscoSansTT Bold', $font-family-sans-serif;
$brand-font-bold-oblique: 'CiscoSansTT Bold Oblique', $font-family-sans-serif;
$brand-font-medium: 'CiscoSansTT Medium', $font-family-sans-serif;
$brand-font-extra-light: 'CiscoSansTT Extra Light', $font-family-sans-serif;
$brand-font-extra-light-oblique: 'CiscoSansTT Extra Light Oblique', $font-family-sans-serif;
$brand-font-heavy: 'CiscoSansTT Heavy', $font-family-sans-serif;
$brand-font-heavy-oblique: 'CiscoSansTT Heavy Oblique', $font-family-sans-serif;
$brand-font-light: 'CiscoSansTT Light', $font-family-sans-serif;
$brand-font-light-Oblique: 'CiscoSansTT Light Oblique', $font-family-sans-serif;
$brand-font-regular: 'CiscoSansTT Regular', $font-family-sans-serif;
$brand-font-regular-oblique: 'CiscoSansTT Regular Oblique', $font-family-sans-serif;
$brand-font-thin: 'CiscoSansTT Thin', $font-family-sans-serif;
$brand-font-thin-oblique: 'CiscoSansTT Thin Oblique', $font-family-sans-serif;
